import React, { useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
const history = require("../../../history");
import axios from "axios";
import config from "../../../config.json";
import "./allFiche.css";

import MessageModal from "../modal/sendMessageModal/sendMessageModal";
import WaitModal from "../modal/waitingModal/waitingModal";
import LooseModal from "../modal/looseModal/looseModal";

import WaitingGray from "../../../assets/images/icon/waiting.png";
import WinGray from "../../../assets/images/icon/win.png";
import LooseGray from "../../../assets/images/icon/trash.png";
import DefaultProfile from "../../../assets/images/icon/default_icon.png";

import Map from "../maps/Maps";
import { wait } from "@testing-library/user-event/dist/utils";

const AllFiche = (props) => {
  const sendMessageModalFunction = useRef(null);
  const looseModalFunction = useRef(null);
  const winModalFunction = useRef(null);
  const waitModalFunction = useRef(null);
  useEffect(() => {}, [props]);

  const todoHandler = async () => {
    const token = await localStorage.getItem("token");
    axios
      .post(config.server_ip + "/api/opportunity/setTodo", {
        token,
        opportunityId: props.opportunity._id,
        organisationId: await localStorage.getItem("CurrentOrganisation"),
      })
      .then(async (response) => {})
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: "bottom-right",
          theme: "dark",
        });
      });
  };

  const timestampToDDMMYYYYHHMM = (timestamp) => {
    const date = new Date(parseInt(timestamp));

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const paddedDay = day < 10 ? "0" + day : day;
    const paddedMonth = month < 10 ? "0" + month : month;
    const paddedHours = hours < 10 ? "0" + hours : hours;
    const paddedMinutes = minutes < 10 ? "0" + minutes : minutes;

    return `${paddedDay}/${paddedMonth}/${year} ${paddedHours}:${paddedMinutes}`;
  };

  const setMessageModal = (state) => {
    if (sendMessageModalFunction.current) {
      sendMessageModalFunction.current(state);
    }
  };

  const timestampToDDMMYYYY = (timestamp) => {
    const date = new Date(parseInt(timestamp));

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const paddedDay = day < 10 ? "0" + day : day;
    const paddedMonth = month < 10 ? "0" + month : month;

    return `${paddedDay}/${paddedMonth}/${year}`;
  };

  const messageHandler = async (data) => {
    const token = await localStorage.getItem("token");
    axios
      .post(config.server_ip + "/api/messages/post", {
        token,
        opportunityId: props._id,
        organisationId: await localStorage.getItem("CurrentOrganisation"),
        message: data.comment,
        statusId: data.status,
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {});
  };

  const setWaitingModal = (state) => {
    const date = document.getElementById("waitingDate").value;
    if (date === "")
      return toast.error("Veuillez renseigner une date", {
        position: "bottom-right",
        theme: "dark",
      });
    if (waitModalFunction.current) {
      waitModalFunction.current(state);
    }
  };

  const setLooseModal = (state) => {
    if (looseModalFunction.current) {
      looseModalFunction.current(state);
    }
  };

  const setWinModal = (state) => {
    if (winModalFunction.current) {
      winModalFunction.current(state);
    }
  };

  const waitingHandler = async () => {
    const date = document.getElementById("waitingDate").value;
    if (date === "")
      return toast.error("Veuillez renseigner une date", {
        position: "bottom-right",
        theme: "dark",
      });
    const timestamp = new Date(date).getTime();
    const token = await localStorage.getItem("token");
    axios
      .post(config.server_ip + "/api/opportunity/setWaiting", {
        token,
        opportunityId: props.opportunity._id,
        organisationId: await localStorage.getItem("CurrentOrganisation"),
        date: timestamp,
      })
      .then(async (response) => {})
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: "bottom-right",
          theme: "dark",
        });
      });
  };

  const winHandler = async () => {
    const token = await localStorage.getItem("token");
    axios
      .post(config.server_ip + "/api/opportunity/setWin", {
        token,
        opportunityId: props.opportunity._id,
        organisationId: await localStorage.getItem("CurrentOrganisation"),
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: "bottom-right",
          theme: "dark",
        });
      });
  };

  const looseHandler = async () => {
    const token = await localStorage.getItem("token");
    axios
      .post(config.server_ip + "/api/opportunity/setLoose", {
        token,
        opportunityId: props.opportunity._id,
        organisationId: await localStorage.getItem("CurrentOrganisation"),
      })
      .then((response) => {})
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: "bottom-right",
          theme: "dark",
        });
      });
  };

  const openMenu = (menu) => {
    const waiting = document.getElementById("waiting" + props.opportunity._id);
    const waitingButton = document.getElementById(
      "waitingButton" + props.opportunity._id
    );
    const win = document.getElementById("win" + props.opportunity._id);
    const winButton = document.getElementById(
      "winButton" + props.opportunity._id
    );

    const todo = document.getElementById("todo" + props.opportunity._id);
    const todoButton = document.getElementById(
      "todoButton" + props.opportunity._id
    );

    const component = document.getElementById(props.opportunity._id);

    if (menu === "waiting") {
      if (waiting.style.display === "flex") {
        waiting.style.display = "none";
        waitingButton.classList.remove("fiche-active");
      } else {
        waiting.style.display = "flex";
        waitingButton.classList.add("fiche-active");
        win.style.display = "none";
        winButton.classList.remove("fiche-active");
        todo.style.display = "none";
        todoButton.classList.remove("fiche-active");
        component.style.display = "none";
      }
    } else if (menu === "win") {
      if (win.style.display === "flex") {
        win.style.display = "none";
        winButton.classList.remove("fiche-active");
      } else {
        win.style.display = "flex";
        winButton.classList.add("fiche-active");
        waiting.style.display = "none";
        waitingButton.classList.remove("fiche-active");
        todo.style.display = "none";
        todoButton.classList.remove("fiche-active");
        component.style.display = "none";
      }
    } else if (menu === "todo") {
      if (todo.style.display === "flex") {
        todo.style.display = "none";
        todoButton.classList.remove("fiche-active");
      } else {
        todo.style.display = "flex";
        todoButton.classList.add("fiche-active");
        waiting.style.display = "none";
        waitingButton.classList.remove("fiche-active");
        win.style.display = "none";
        winButton.classList.remove("fiche-active");
        component.style.display = "none";
      }
    }
  };

  return (
    <div className="allFile-main">
      <div className="allFile-left-component">
        <div className="allFile-left" id={props.opportunity._id}>
          <div
            className={
              "allFile-parameters border-m " +
              (props.opportunity.state === "TODO"
                ? "border-todo"
                : "border-gradient")
            }
            onClick={() => openMenu("todo")}
            id={"todoButton" + props.opportunity._id}
          >
            <p>A Faire</p>
            <img className="allFile-wait" src={WaitingGray} alt="waiting" />
          </div>
          <div
            className={
              "allFile-parameters border-m " +
              (props.opportunity.state === "WAITING"
                ? "border-wait"
                : "border-gradient")
            }
            onClick={() => openMenu("waiting")}
            id={"waitingButton" + props.opportunity._id}
          >
            <p>En Attente</p>
            <img className="allFile-wait" src={WaitingGray} alt="waiting" />
          </div>
          <div
            className={
              "allFile-parameters border-m " +
              (props.opportunity.state === "WIN"
                ? "border-win"
                : "border-gradient")
            }
            onClick={() => openMenu("win")}
            id={"winButton" + props.opportunity._id}
          >
            <p>Gagné</p>
            <img className="allFile-win" src={WinGray} alt="win" />
          </div>
          <div
            className={
              "allFile-parameters border-m " +
              (props.opportunity.state === "LOOSE"
                ? "border-loose"
                : "border-gradient")
            }
            onClick={() => setLooseModal(true)}
          >
            <p>Perdu</p>
            <img className="allFile-loose" src={LooseGray} alt="loose" />
          </div>
        </div>
        <div
          className="myLeftComponents"
          style={{ display: "none" }}
          id={"todo" + props.opportunity._id}
        >
          <div className="allFile-left-menu border-todo border-m">
            <p>A faire</p>
            <input type="datetime-local" id="todoDate" className="dateSelect" />
            <span
              className="allFile-left-button border-gradient border-xl"
              onClick={() => todoHandler()}
            >
              <p>Validation</p>
            </span>
          </div>
          <div className="bubbleComponent">
            <span
              className="bubble border-gradient border-xl"
              onClick={() => openMenu("waiting")}
            >
              <img src={WaitingGray} alt="waiting" width="12px" height="18px" />
            </span>
            <span
              className="bubble border-gradient border-xl"
              onClick={() => openMenu("win")}
            >
              <img src={WinGray} alt="win" width="16px" height="16px" />
            </span>
            <span
              className="bubble border-gradient border-xl"
              onClick={() => setLooseModal(true)}
            >
              <img src={LooseGray} alt="loose" width="15px" height="18px" />
            </span>
          </div>
        </div>
        <div
          id={"waiting" + props.opportunity._id}
          style={{ display: "none" }}
          className="myLeftComponents"
        >
          <div className="allFile-left-menu border-wait border-m">
            <p>En attente</p>
            <input
              type="datetime-local"
              id="waitingDate"
              className="dateSelect"
            />
            <span
              className="allFile-left-button border-gradient border-xl"
              onClick={() => setWaitingModal(true)}
            >
              <p>Validation</p>
            </span>
          </div>
          <div className="bubbleComponent">
            <span
              className="bubble border-gradient border-xl"
              onClick={() => openMenu("todo")}
            >
              <img src={WaitingGray} alt="waiting" width="12px" height="18px" />
            </span>
            <span
              className="bubble border-gradient border-xl"
              onClick={() => openMenu("win")}
            >
              <img src={WinGray} alt="win" width="16px" height="16px" />
            </span>
            <span
              className="bubble border-gradient border-xl"
              onClick={() => setLooseModal(true)}
            >
              <img src={LooseGray} alt="loose" width="15px" height="18px" />
            </span>
          </div>
        </div>
        <div
          className="myLeftComponents"
          style={{ display: "none" }}
          id={"win" + props.opportunity._id}
        >
          <div className="allFile-left-menu border-wait border-m">
            <p>Gagné</p>
            <input type="datetime-local" className="dateSelect" />
            <span
              className="allFile-left-button border-gradient border-xl"
              onClick={() => setWinModal(true)}
            >
              <p>Validation</p>
            </span>
          </div>
          <div className="bubbleComponent">
            <span
              className="bubble border-gradient border-xl"
              onClick={() => openMenu("todo")}
            >
              <img src={WaitingGray} alt="waiting" width="12px" height="18px" />
            </span>
            <span
              className="bubble border-gradient border-xl"
              onClick={() => openMenu("todo")}
            >
              <img src={WaitingGray} alt="todo" width="12px" height="18px" />
            </span>
            <span
              className="bubble border-gradient border-xl"
              onClick={() => setLooseModal(true)}
            >
              <img src={LooseGray} alt="loose" width="15px" height="18px" />
            </span>
          </div>
        </div>
      </div>
      <div
        className="allFile-right border-gradient border-m"
        onClick={() =>
          (window.location.href = `/dashboard/opportunity/${props.opportunity._id}?type=none`)
        }
      >
        <div className="allFile-right-top">
          <img src={DefaultProfile} alt="profile" />
          <p>{props.opportunity?.prospectId.prospect}</p>
          <p className="allFile-date">
            Crée le : {timestampToDDMMYYYY(props.opportunity?.creationDate)}
          </p>
          <p className="allFile-date">
            Modifier le :{" "}
            {timestampToDDMMYYYY(props.opportunity?.lastModificationDate)}
          </p>
        </div>
        <div className="allFile-right-bottom">
          <div className="left_main_left-">
            <div className="messages-">
              {props.opportunity.message.length == 0 ? (
                <p>Aucun message</p>
              ) : (
                <>
                  <div className="messages_top-">
                    <div>
                      <p className="messages_status-">
                        {props?.opportunity?.message[0]?.statusId.name}
                      </p>
                    </div>
                    <div>
                      <p className="messages_date-">
                        {timestampToDDMMYYYY(
                          props?.opportunity?.message[
                            props?.opportunity?.message.length - 1
                          ]?.date
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="messages_bottom-">
                    <p>
                      <span>
                        @
                        {
                          props?.opportunity?.message[
                            props?.opportunity?.message.length - 1
                          ]?.userId.username
                        }
                      </span>{" "}
                      {
                        props?.opportunity?.message[
                          props?.opportunity?.message.length - 1
                        ]?.text
                      }
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="allFile-right-content-right">
            <div className="information-container">
              <p>
                Enseigne :{" "}
                {props.opportunity?.prospectId?.enseigne
                  ? props.opportunity.prospectId.enseigne
                  : "Aucun"}
              </p>
              <p>
                Code Postal :{" "}
                {props.opportunity?.prospectId?.codePostale
                  ? props.opportunity.prospectId.codePostale
                  : "Aucun"}
              </p>
              <p>
                Adresse Complete :{" "}
                {props.opportunity?.prospectId?.adresseComplete
                  ? props.opportunity.prospectId.adresseComplete
                  : "Aucun"}
              </p>
              <p>
                Site :{" "}
                {props.opportunity?.prospectId?.site
                  ? props.opportunity.prospectId.site
                  : "Aucun"}
              </p>
              <p>
                Téléphone :{" "}
                {props.opportunity?.prospectId?.telephone
                  ? props.opportunity.prospectId.telephone
                  : "Aucun"}
              </p>
              <p>
                Email :{" "}
                {props.opportunity?.prospectId?.eMail
                  ? props.opportunity.prospectId.eMail
                  : "Aucun"}
              </p>
              <p>
                Siret :{" "}
                {props.opportunity?.prospectId?.siret
                  ? props.opportunity.prospectId.siret
                  : "Aucun"}
              </p>
              <p>
                Libele activité :{" "}
                {props.opportunity?.prospectId?.libelleactivite
                  ? props.opportunity.prospectId.libelleactivite
                  : "Aucun"}
              </p>
              <p>
                Tranche Salarié :{" "}
                {props.opportunity?.prospectId?.trancheSalarier
                  ? props.opportunity.prospectId.trancheSalarier
                  : "Aucun"}
              </p>
              {Object.keys(
                JSON.parse(props.opportunity?.prospectId?.value)
              ).map((key) => {
                const allKey = props.opportunity.excel;
                if (
                  key == allKey?.adresseComplete ||
                  key == allKey?.codePostale ||
                  key == allKey?.eMail ||
                  key == allKey?.enseigne ||
                  key == allKey?.libelleactivite ||
                  key == allKey?.prospect ||
                  key == allKey?.siret ||
                  key == allKey?.site ||
                  key == allKey?.telephone
                )
                  return;
                if (key === "contact") {
                  const contacts = JSON.parse(
                    props.opportunity?.prospectId?.value
                  )[key];

                  const contactElements = contacts.map((contact, index) => {
                    return Object.keys(contact).map((contactKey) => {
                      return (
                        <p key={`${index}-${contactKey}`}>
                          Contact #{index + 1} | {contactKey} :{" "}
                          {contact[contactKey] === undefined
                            ? "Aucun"
                            : contact[contactKey]}
                        </p>
                      );
                    });
                  });

                  return <>{contactElements}</>;
                } else if (
                  JSON.parse(props.opportunity?.prospectId?.value)[key] ==
                  undefined
                )
                  return;
                else
                  return (
                    <p key={key}>
                      {key} :{" "}
                      {JSON.parse(props.opportunity?.prospectId?.value)[key]}
                    </p>
                  );
              })}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={8000} />
      <LooseModal setShowModal={looseModalFunction} setLoose={looseHandler} />
      <LooseModal setShowModal={winModalFunction} setLoose={winHandler} />
      <MessageModal
        setShowModal={sendMessageModalFunction}
        setMessages={messageHandler}
        messages={props.opportunity?.message}
      />
      <WaitModal
        setShowModal={waitModalFunction}
        setWaiting={waitingHandler}
        messages={props.opportunity?.message}
      />
    </div>
  );
};

export default AllFiche;
