import React, { useEffect, useRef } from "react";
import axios from "axios";
import config from "../../../../config.json";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ProgressBar from "@ramonak/react-progress-bar";
import history from "../../../../history";

import "./ProspectInformation.css";
import "react-toastify/dist/ReactToastify.css";

import loader from "../../../../assets/images/icon/loader.png";
import loupe from "../../../../assets/images/icon/loupe.png";
import com from "../../../../assets/images/icon/com.png";
import defaultProfil from "../../../../assets/images/icon/default_icon.png";
import plus from "../../../../assets/images/icon/plus.png";
import thumbDown from "../../../../assets/images/icon/thumbDown.png";
import waiting from "../../../../assets/images/icon/waiting.png";
import win from "../../../../assets/images/icon/win.png";

import Navbar from "../../../navbar/navbar";
import Clock from "../../../widgets/clock/Clock";

const OpportunityDetails = () => {
  const { id } = useParams();
  const [alreadyLoaded, setAlreadyLoaded] = React.useState(false);
  const [organisation, setOrganisation] = React.useState([]);
  const [currentOrganisation, setCurrentOrganisation] = React.useState("");
  const [prospects, setProspects] = React.useState(null);
  const [saveProspect, setSaveProspect] = React.useState(null);
  const [excel, setExcel] = React.useState(null);

  useEffect(() => {
    const main = async () => {
      if (alreadyLoaded) return;
      const token = await localStorage.getItem("token");

      if (organisation.length === 0)
        axios
          .post(config.server_ip + "/api/organisation/get", { token })
          .then(async (response) => {
            if (response.data.organisations.length == 0)
              history.push("/dashboard");
            setOrganisation(response.data.organisations);
            if (
              (await localStorage.getItem("CurrentOrganisation")) == undefined
            ) {
              localStorage.setItem(
                "CurrentOrganisation",
                response.data.organisations[0]._id
              );
              setCurrentOrganisation(response.data.organisations[0]._id);
            } else {
              setCurrentOrganisation(
                await localStorage.getItem("CurrentOrganisation")
              );
            }
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: "bottom-right",
              theme: "dark",
            });
          });

      if (!prospects)
        axios
          .post(config.server_ip + "/api/prospect/get/value", {
            token,
            organisationId: await localStorage.getItem("CurrentOrganisation"),
            excelId: id,
          })
          .then((response) => {
            setProspects(response.data.value);
            setSaveProspect(response.data.value);
          })
          .catch((error) => {});

      if (!excel)
        axios
          .post(config.server_ip + "/api/prospect/get/excel/one", {
            token,
            organisationId: await localStorage.getItem("CurrentOrganisation"),
            excelId: id,
          })
          .then((response) => {
            setExcel(response.data.excel);
          })
          .catch((error) => {});

      setAlreadyLoaded(true);
    };

    main();
  }, [currentOrganisation, prospects]);

  const searchHandler = (e) => {
    const search = e.target.value;
    if (search === "") {
      setProspects(saveProspect);
      return;
    }

    const newProspects = saveProspect.filter((prospect) => {
      return (
        prospect.prospect.toLowerCase().includes(search.toLowerCase()) ||
        prospect.enseigne.toLowerCase().includes(search.toLowerCase()) ||
        prospect.codePostale.toLowerCase().includes(search.toLowerCase()) ||
        prospect.ville.toLowerCase().includes(search.toLowerCase()) ||
        prospect.adresseComplete.toLowerCase().includes(search.toLowerCase()) ||
        prospect.site.toLowerCase().includes(search.toLowerCase()) ||
        prospect.telephone.toLowerCase().includes(search.toLowerCase())
      );
    });

    setProspects(newProspects);
  };

  const handleAccept = async (prospectId, event, id) => {
    const token = await localStorage.getItem("token");

    axios
      .post(config.server_ip + "/api/opportunity/create", {
        token,
        organisationId: await localStorage.getItem("CurrentOrganisation"),
        prospectId: prospectId,
      })
      .then((response) => {
        const myDiv = document.getElementById(id);
        myDiv.classList.remove("tableAddButton");
        myDiv.classList.add("tableWaitingButton");
        myDiv.childNodes[0].src = waiting;
      })
      .catch((error) => {});
  };

  return (
    <div className="prospect_background_component">
      <Navbar
        currentOrganisation={organisation.filter(
          (o) => o["_id"] === currentOrganisation
        )}
        selected="Home"
        allOrganisation={organisation}
        setOrga={(organisationId) => {
          setAlreadyLoaded(false);
          setProspects(null);
          setCurrentOrganisation(organisationId);
        }}
      />
      <div className="main">
        <div className="main_header">
          <Clock />
        </div>
        {alreadyLoaded && prospects && (
          <div className="myMain opportunityList border-gradient border-fullscreen">
            <div className="excelListHeader">
              <h2>{excel?.name}</h2>
              <div className="excelButtonContainer">
                <div className="excelSearch">
                  <div className="excelSearch-image border-gradient border-fullscreen">
                    <img src={loupe} alt="logo" />
                  </div>
                  <input
                    type="text"
                    placeholder="Rechercher"
                    onChange={(e) => searchHandler(e)}
                  />
                </div>
              </div>
            </div>
            <div className="myTable">
              <table>
                <thead>
                  <tr>
                    <th scope="col" className="table2Action">
                      Action
                    </th>
                    <th scope="col" className="table2Com">
                      Com
                    </th>
                    <th scope="col" className="table2Utilisateur">
                      Utilisateur
                    </th>
                    <th scope="col" className="table2Prospect">
                      Prospect
                    </th>
                    <th scope="col" className="table2Enseigne">
                      Enseigne
                    </th>
                    <th scope="col" className="table2CodePostal">
                      Code Postal
                    </th>
                    <th scope="col" className="table2Ville">
                      Ville
                    </th>
                    <th scope="col" className="table2AdresseComplete">
                      Adresse Complete
                    </th>
                    <th scope="col" className="table2Site">
                      Site
                    </th>
                    <th scope="col" className="table2Telephone">
                      Téléphone
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {prospects.map((prospect, index) => (
                    <tr key={index}>
                      <td className="table2Action">
                        {!prospect.isAccepted && (
                          <div
                            id={index}
                            onClick={(event) =>
                              handleAccept(prospect._id, event, index)
                            }
                            className="tableAddButton tableFunctionButton"
                          >
                            <img src={plus} alt="Add Button" />
                          </div>
                        )}
                        {prospect.isAccepted &&
                          (prospect.state === "TODO" ||
                            prospect.state === "WAITING") && (
                            <div className="tableWaitingButton tableFunctionButton">
                              <img src={waiting} alt="Waiting Button" />
                            </div>
                          )}
                        {prospect.isAccepted && prospect.state === "WIN" && (
                          <div className="tableWinButton tableFunctionButton">
                            <img src={win} alt="Win Button" />
                          </div>
                        )}
                        {prospect.isAccepted && prospect.state === "LOOSE" && (
                          <div className="tableLooseButton tableFunctionButton">
                            <img src={thumbDown} alt="Lost Button" />
                          </div>
                        )}
                      </td>
                      <td className="table2Com">
                        <img src={com} alt="com Logo" />
                      </td>
                      <td className="table2Utilisateur">
                        <img src={defaultProfil} alt="profil icon" />
                      </td>
                      <td className="table2Prospect selectable">
                        {prospect.prospect || "Aucun"}
                      </td>
                      <td className="table2Enseigne">
                        {prospect.enseigne || "Aucun"}
                      </td>
                      <td className="table2CodePostal">
                        {prospect.codePostale || "Aucun"}
                      </td>
                      <td className="table2Ville">
                        {prospect.ville || "Aucun"}
                      </td>
                      <td className="table2AdresseComplete">
                        {prospect.adresseComplete || "Aucun"}
                      </td>
                      <td className="table2Site">{prospect.site || "Aucun"}</td>
                      <td className="table2Telephone">
                        {prospect.telephone || "Aucun"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {!alreadyLoaded ||
          (!prospects && (
            <div className="loadingScreen">
              <img src={loader} alt="logo" />
            </div>
          ))}
      </div>
      <ToastContainer autoClose={8000} />
    </div>
  );
};

export default OpportunityDetails;
