import React, { useEffect } from "react";
import "../modal.css";
import { toast } from "react-toastify";

import valid from "../../../../assets/images/icon/modal_valid.png";
import plus from "../../../../assets/images/icon/plus.png";

const contractuelleModal = ({
  setShowModal,
  setContractuelle,
  value,
  excel,
  defaultInformation,
}) => {
  const [isActive, setIsActive] = React.useState(false);
  const [validationActive, setValidationActive] = React.useState(false);
  const [menuNumber, setMenuNumber] = React.useState(0);

  const showModal = (state) => {
    setIsActive(state);
  };

  const isValidJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const setValidation = () => {
    let haveError = false;
    const data = {
      prospect: document.getElementById("prospect").value || "Aucun",
      enseigne: document.getElementById("enseigne").value || "Aucun",
      codePostale: document.getElementById("codePostal").value || "Aucun",
      adresseComplete:
        document.getElementById("adresseComplete").value || "Aucun",
      site: document.getElementById("site").value || "Aucun",
      telephone: document.getElementById("telephone").value || "Aucun",
      eMail: document.getElementById("eMail").value || "Aucun",
      siret: document.getElementById("siret").value || "Aucun",
      libelleactivite:
        document.getElementById("libelleactivite").value || "Aucun",
      trancheSalarier:
        document.getElementById("trancheSalarier").value || "Aucun",
      ville: document.getElementById("ville").value || "Aucun",
    };

    if (isValidJson(value)) {
      Object.keys(JSON.parse(value)).map((key, index) => {
        const allKey = excel;
        if (
          key != allKey?.adresseComplete &&
          key != allKey?.codePostale &&
          key != allKey?.eMail &&
          key != allKey?.enseigne &&
          key != allKey?.libelleactivite &&
          key != allKey?.prospect &&
          key != allKey?.siret &&
          key != allKey?.site &&
          key != allKey?.telephone
        ) {
          if (document.getElementById("key:" + key) == null) return;
          const myKey = document.getElementById("key:" + key)?.value;
          const myValue = document.getElementById("key:" + key + "2")?.value;
          if (
            myKey == "" ||
            myValue == "" ||
            myKey == null ||
            myValue == null ||
            myKey == undefined ||
            myValue == undefined
          ) {
            haveError = true;
            return toast.error(
              "Veuillez remplir tous les champs avant de valider",
              {
                position: "bottom-right",
                theme: "dark",
              }
            );
          }
          data[myKey] = myValue;
        }
      });
    }

    Array.from(Array(menuNumber)).map((e, i) => {
      const myKey = document.getElementById("custom" + i).value;
      const myValue = document.getElementById("custom" + i + "value").value;

      if (myKey == "" || myValue == "" || myKey == null || myValue == null) {
        haveError = true;
        return toast.error(
          "Veuillez remplir tous les champs avant de valider",
          {
            position: "bottom-right",
            theme: "dark",
          }
        );
      }
      data[myKey] = myValue;
    });

    if (haveError) return;

    setValidationActive(true);
    setTimeout(() => {
      setContractuelle(data);
      closeModal(true);
    }, 2000);
  };

  const closeModal = (needRefresh) => {
    const menu = document.getElementById("myModal");
    menu.classList.add("modal-fadeOut");
    setTimeout(() => {
      setIsActive(false);
      if (needRefresh) window.location.reload();
      setTimeout(() => {
        setValidationActive(false);
        menu.classList.remove("modal-fadeOut");
      }, 100);
    }, 200);
  };

  useEffect(() => {
    if (setShowModal.current === null) {
      setShowModal.current = showModal;
    }
  }, [setShowModal, showModal, setMenuNumber, menuNumber]);
  return (
    <div>
      {isActive && (
        <div id="myModal" className="myModal">
          {!validationActive && (
            <div className="myModal-content border-gradient border-l">
              <p className="myModal-title">Information prospect</p>
              <p className="myModal-description">
                Information Prospect à enregistrer
              </p>

              <p className="custom-title">Information</p>

              <div className="custom-height">
                <div className="custom-input-content">
                  <label className="">Prospect :</label>
                  <input
                    type="text"
                    id="prospect"
                    placeholder=""
                    defaultValue={
                      !defaultInformation || !defaultInformation.prospect
                        ? "Aucun"
                        : defaultInformation.prospect
                    }
                  />
                </div>
                <div className="custom-input-content">
                  <label className="">Enseigne :</label>
                  <input
                    type="text"
                    id="enseigne"
                    placeholder=""
                    defaultValue={
                      !defaultInformation || !defaultInformation.enseigne
                        ? "Aucun"
                        : defaultInformation.enseigne
                    }
                  />
                </div>
                <div className="custom-input-content">
                  <label className="">Code Postal :</label>
                  <input
                    type="text"
                    id="codePostal"
                    placeholder=""
                    defaultValue={
                      !defaultInformation || !defaultInformation.codePostale
                        ? "Aucun"
                        : defaultInformation.codePostale
                    }
                  />
                </div>
                <div className="custom-input-content">
                  <label className="">Adresse Complete :</label>
                  <input
                    type="text"
                    id="adresseComplete"
                    placeholder=""
                    defaultValue={
                      !defaultInformation || !defaultInformation.adresseComplete
                        ? "Aucun"
                        : defaultInformation.adresseComplete
                    }
                  />
                </div>
                <div className="custom-input-content">
                  <label className="">Ville :</label>
                  <input
                    type="text"
                    id="ville"
                    placeholder=""
                    defaultValue={
                      !defaultInformation || !defaultInformation.ville
                        ? "Aucun"
                        : defaultInformation.ville
                    }
                  />
                </div>
                <div className="custom-input-content">
                  <label className="">Site :</label>
                  <input
                    type="text"
                    id="site"
                    placeholder=""
                    defaultValue={
                      !defaultInformation || !defaultInformation.site
                        ? "Aucun"
                        : defaultInformation.site
                    }
                  />
                </div>
                <div className="custom-input-content">
                  <label className="">Téléphone :</label>
                  <input
                    type="text"
                    id="telephone"
                    placeholder=""
                    defaultValue={
                      !defaultInformation || !defaultInformation.telephone
                        ? "Aucun"
                        : defaultInformation.telephone
                    }
                  />
                </div>
                <div className="custom-input-content">
                  <label className="">Email :</label>
                  <input
                    type="text"
                    id="eMail"
                    placeholder=""
                    defaultValue={
                      !defaultInformation || !defaultInformation.eMail
                        ? "Aucun"
                        : defaultInformation.eMail
                    }
                  />
                </div>
                <div className="custom-input-content">
                  <label className="">Siret :</label>
                  <input
                    type="text"
                    id="siret"
                    placeholder=""
                    defaultValue={
                      !defaultInformation || !defaultInformation.siret
                        ? "Aucun"
                        : defaultInformation.siret
                    }
                  />
                </div>
                <div className="custom-input-content">
                  <label className="">Libele activité :</label>
                  <input
                    type="text"
                    id="libelleactivite"
                    placeholder=""
                    defaultValue={
                      !defaultInformation || !defaultInformation.libelleactivite
                        ? "Aucun"
                        : defaultInformation.libelleactivite
                    }
                  />
                </div>
                <div className="custom-input-content">
                  <label className="">Tranche Salarié :</label>
                  <input
                    type="text"
                    id="trancheSalarier"
                    placeholder=""
                    defaultValue={
                      !defaultInformation || !defaultInformation.trancheSalarier
                        ? "Aucun"
                        : defaultInformation.trancheSalarier
                    }
                  />
                </div>
                {isValidJson(value) &&
                  Object.keys(JSON.parse(value)).map((key, index) => {
                    const allKey = excel;
                    if (
                      key != allKey?.adresseComplete &&
                      key != allKey?.codePostale &&
                      key != allKey?.eMail &&
                      key != allKey?.enseigne &&
                      key != allKey?.libelleactivite &&
                      key != allKey?.prospect &&
                      key != allKey?.siret &&
                      key != allKey?.site &&
                      key != allKey?.telephone &&
                      key != allKey?.ville &&
                      JSON.parse(value)[key] != "" &&
                      JSON.parse(value)[key] != null &&
                      JSON.parse(value)[key] != undefined
                    )
                      return (
                        <div
                          className="custom-input-content"
                          key={index + "bipbip"}
                        >
                          <input
                            type="text"
                            id={"key:" + key}
                            placeholder="Titre"
                            className="modal-right-input custom-input-title"
                            defaultValue={!key || key == "" ? "Aucun" : key}
                          />
                          <input
                            type="text"
                            id={"key:" + key + "2"}
                            placeholder="Valeur"
                            className="custom-input-value"
                            defaultValue={
                              !JSON.parse(value)[key] ||
                              JSON.parse(value)[key] == ""
                                ? "Aucun"
                                : JSON.parse(value)[key]
                            }
                          />
                        </div>
                      );
                  })}

                {Array.from(Array(menuNumber)).map((e, i) => (
                  <div className="custom-input-content" key={i}>
                    <input
                      type="text"
                      id={"custom" + i}
                      placeholder="Titre"
                      className="modal-right-input custom-input-title"
                    />
                    <input
                      type="text"
                      id={"custom" + i + "value"}
                      placeholder="Valeur"
                      className="custom-input-value"
                    />
                  </div>
                ))}
              </div>

              <div
                className="custom-add-message"
                onClick={() => setMenuNumber(menuNumber + 1)}
              >
                <img className="plus-icon" src={plus} alt="plus" />
                <p>Ajouter une valeur</p>
              </div>

              <div className="myModal-button">
                <div
                  className="save-button border-fullscreen border-gradient"
                  onClick={() => setValidation()}
                >
                  Enregistrer
                </div>
                <div className="cancel-button" onClick={() => closeModal()}>
                  Annuler
                </div>
              </div>
            </div>
          )}
          {validationActive && (
            <div className="myModal-content border-gradient border-l">
              <img className="myModal-img" src={valid} alt="checked" />
              <p className="myModal-title">Validé</p>
              <p className="myModal-description">Information Enregistrer</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default contractuelleModal;
