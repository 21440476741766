import React, { useEffect } from "react";
import history from "../../../history";

const Login = () => {
  useEffect(() => {
    history.push("/login");
  }, []);

  return <div className="background_component"></div>;
};

export default Login;
