import React from "react";
import "./Fiche.css";
import history from "../../../../history";

import Phone from "../../../../assets/images/icon/phone.png";
import Mail from "../../../../assets/images/icon/email.png";
import Location from "../../../../assets/images/icon/city.png";
import Prospect from "../../../../assets/images/icon/work.png";
import DefaultProfil from "../../../../assets/images/icon/default_icon.png";
import Win from "../../../../assets/images/icon/win.png";
import Wait from "../../../../assets/images/icon/waiting.png";
import Trash from "../../../../assets/images/icon/trash.png";

const Fiche = (props) => {
  const timestampToDDMMYYYY = (timestamp) => {
    const date = new Date(parseInt(timestamp));
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return day + "/" + month + "/" + year;
  };

  function formatString(str) {
    if (str.length > 7) {
      return str.substring(0, 5) + "..";
    }
    return str;
  }

  return (
    <div
      className="fiches_components border-gradient border-m"
      onClick={() => {
        if (props.type) {
          return history.push(
            "/dashboard/opportunity/" +
              props.opportunity._id +
              "?type=" +
              props.type
          );
        }
        history.push("/dashboard/opportunity/" + props.opportunity._id);
      }}
    >
      <div className="fiche_left">
        <div className="left_header_">
          <div>
            <img src={DefaultProfil} alt="fiche" />
            <p className="selectable">
              {props.opportunity.user.username} |{" "}
              {formatString(props.opportunity.prospectId.prospect)}
            </p>
          </div>
          <div>
            <div
              className={
                "info_status border-fullscreen " +
                (props.opportunity.state == "WAITING"
                  ? "border-wait"
                  : "border-gradient")
              }
            >
              <img
                src={Wait}
                alt="wait"
                style={{ width: "9px", height: "12px" }}
              />
            </div>
            <div
              className={
                "info_status border-fullscreen " +
                (props.opportunity.state == "WIN"
                  ? "border-win"
                  : "border-gradient")
              }
            >
              <img
                src={Win}
                alt="win"
                style={{ width: "12px", height: "12px" }}
              />
            </div>
            <div
              className={
                "info_status border-fullscreen " +
                (props.opportunity.state == "LOOSE"
                  ? "border-loose"
                  : "border-gradient")
              }
              style={{ marginRight: "10px" }}
            >
              <img
                src={Trash}
                alt="trash"
                style={{ width: "11px", height: "12px" }}
              />
            </div>
          </div>
        </div>
        <div className="left_main_">
          <div className="left_main_left">
            <div className="messages" style={{ height: "71px" }}>
              {props.opportunity.message.length == 0 ? (
                <p>Aucun message</p>
              ) : (
                <>
                  <div className="messages_top">
                    <div>
                      <p className="messages_status">
                        {props?.opportunity?.message[0]?.statusId.name}
                      </p>
                    </div>
                    <div>
                      <p className="messages_date">
                        {timestampToDDMMYYYY(
                          props?.opportunity?.message[
                            props?.opportunity?.message.length - 1
                          ]?.date
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="messages_bottom">
                    <p>
                      {
                        props?.opportunity?.message[
                          props?.opportunity?.message.length - 1
                        ]?.text
                      }
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="fiche_right">
        <div className="elements">
          <img src={Phone} className="phone_icon" alt="fiche" />
          <p>
            {props.opportunity.prospectId.telephone
              ? props.opportunity.prospectId.telephone
              : "Aucun"}
          </p>
        </div>
        <div className="elements">
          <img src={Mail} className="mail_icon" alt="fiche" />
          <p>
            {props.opportunity.prospectId.eMail
              ? props.opportunity.prospectId.eMail
              : "Aucun"}
          </p>
        </div>
        <div className="elements">
          <img src={Location} alt="fiche" />
          <p>
            {props.opportunity.prospectId.ville
              ? props.opportunity.prospectId.ville
              : "Aucun"}
          </p>
        </div>
        <div className="elements">
          <img src={Prospect} alt="fiche" />
          <p>
            {props.opportunity.prospectId.prospect
              ? props.opportunity.prospectId.prospect
              : "Aucun"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Fiche;
