import React from "react";

function arrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 23 36"
      className="arrowSVG"
    >
      <defs></defs>
      <g>
        <g id="Calque_1">
          <path
            class="cls-1"
            d="M4,35.5c-.9,0-1.9-.4-2.6-1.1-1.3-1.4-1.2-3.6.2-4.9l12.3-11.4L1.6,6.6C.2,5.2.1,3,1.4,1.6,2.8.2,5,.1,6.4,1.4l15,14c.7.7,1.1,1.6,1.1,2.6s-.4,1.9-1.1,2.6l-15,14c-.7.6-1.5.9-2.4.9Z"
          />
        </g>
      </g>
    </svg>
  );
}

export { arrow };
