import React, { useEffect } from "react";
import "../modal.css";
import "./contractuelleModal.css";
import { toast } from "react-toastify";

import valid from "../../../../assets/images/icon/modal_valid.png";
import plus from "../../../../assets/images/icon/plus.png";

const contractuelleModal = ({ setShowModal, setContractuelle, value }) => {
  const [isActive, setIsActive] = React.useState(false);
  const [validationActive, setValidationActive] = React.useState(false);
  const [menuNumber, setMenuNumber] = React.useState(0);

  const showModal = (state) => {
    setIsActive(state);
  };

  const isValidJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const setValidation = () => {
    if (document.getElementById("typeInvestissement").value == "null")
      return toast.error("Veuillez selectionner un type d'investissement", {
        position: "bottom-right",
        theme: "dark",
      });

    const allCustomInputTitle = document.querySelectorAll(
      ".custom-input-title"
    );
    const allCustomInputValue = document.querySelectorAll(
      ".custom-input-value"
    );

    console.log(allCustomInputTitle, allCustomInputValue);

    for (let i = 0; i < allCustomInputTitle.length; i++) {
      console.log(allCustomInputTitle[i]?.value, allCustomInputValue[i]?.value);
      if (
        allCustomInputTitle[i]?.value == "" ||
        allCustomInputValue[i]?.value == "" ||
        allCustomInputTitle[i]?.value == undefined ||
        allCustomInputValue[i]?.value == undefined
      ) {
        return toast.error("Veuillez remplir tous les champs", {
          position: "bottom-right",
          theme: "dark",
        });
      }
    }

    for (let i = 0; i < allCustomInputValue.length; i++) {
      if (
        allCustomInputTitle[i]?.value == "" ||
        allCustomInputValue[i]?.value == "" ||
        allCustomInputTitle[i]?.value == null ||
        allCustomInputValue[i]?.value == null
      ) {
        return toast.error("Veuillez remplir tous les champs", {
          position: "bottom-right",
          theme: "dark",
        });
      }
    }

    const data = {
      "Type_D'investissement":
        document.getElementById("typeInvestissement").value,
      Date_previsionelle_fin_de_contrat:
        document.getElementById("dateEnd").value,
      Prestation_Actuelle: document.getElementById("actualPrestation").value,
      Prestataire_Actuelle: document.getElementById("actualPrestataire").value,
    };

    for (let i = 0; i < allCustomInputTitle.length; i++)
      data[allCustomInputTitle[i].value] = allCustomInputValue[i].value;

    setValidationActive(true);
    setTimeout(() => {
      setContractuelle(data);
      closeModal(true);
    }, 2000);
  };

  const closeModal = (needRefresh) => {
    const menu = document.getElementById("myModal");
    menu.classList.add("modal-fadeOut");
    setTimeout(() => {
      setIsActive(false);
      if (needRefresh) window.location.reload();
      setTimeout(() => {
        setValidationActive(false);
        menu.classList.remove("modal-fadeOut");
      }, 100);
    }, 200);
  };

  useEffect(() => {
    if (setShowModal.current === null) {
      setShowModal.current = showModal;
    }
  }, [setShowModal, showModal, setMenuNumber, menuNumber]);
  return (
    <div>
      {isActive && (
        <div id="myModal" className="myModal">
          {!validationActive && (
            <div className="myModal-content border-gradient border-l">
              <p className="myModal-title">Information Contractuelle</p>
              <p className="myModal-description">
                Information Contractuelle à enregistrer
              </p>

              <p className="custom-title">Information</p>

              <div className="custom-input-content">
                <label className="">Type investissement : </label>
                <select
                  id="typeInvestissement"
                  name="typeInvestissement"
                  className="custom-select"
                >
                  {!value ||
                  JSON.parse(value)["Type_D'investissement"] == null ? (
                    <option value="null" selected>
                      Aucun
                    </option>
                  ) : (
                    <option value="null">Aucun</option>
                  )}

                  {value &&
                  JSON.parse(value)["Type_D'investissement"] == "location" ? (
                    <option value="location" selected>
                      Location
                    </option>
                  ) : (
                    <option value="location">Location</option>
                  )}

                  {value &&
                  JSON.parse(value)["Type_D'investissement"] == "achat" ? (
                    <option value="achat" selected>
                      Achat
                    </option>
                  ) : (
                    <option value="achat">Achat</option>
                  )}
                </select>
              </div>
              <div className="custom-input-content">
                <label className="">Date previsionelle fin de contrat :</label>
                {!value ||
                !JSON.parse(value).Date_previsionelle_fin_de_contrat ? (
                  <input
                    type="date"
                    id="dateEnd"
                    min={new Date().toISOString().split("T")[0]}
                  />
                ) : (
                  <input
                    type="date"
                    id="dateEnd"
                    min={new Date().toISOString().split("T")[0]}
                    defaultValue={
                      JSON.parse(value).Date_previsionelle_fin_de_contrat
                    }
                  />
                )}
              </div>
              {!value || !JSON.parse(value).Prestation_Actuelle ? (
                <div className="custom-input-content">
                  <label className="">Prestation actuelle :</label>
                  <input
                    type="text"
                    id="actualPrestation"
                    placeholder="Prestation actuelle"
                  />
                </div>
              ) : (
                <div className="custom-input-content">
                  <label className="">Prestation actuelle :</label>
                  <input
                    type="text"
                    id="actualPrestation"
                    defaultValue={JSON.parse(value).Prestation_Actuelle}
                  />
                </div>
              )}
              {!value || !JSON.parse(value).Prestataire_Actuelle ? (
                <div className="custom-input-content">
                  <label className="">Prestataire actuel :</label>
                  <input
                    type="text"
                    id="actualPrestataire"
                    placeholder="Prestataire actuel"
                  />
                </div>
              ) : (
                <div className="custom-input-content">
                  <label className="">Prestataire actuel :</label>
                  <input
                    type="text"
                    id="actualPrestataire"
                    defaultValue={JSON.parse(value).Prestataire_Actuelle}
                  />
                </div>
              )}

              {isValidJson(value) &&
                Object.keys(JSON.parse(value)).map((key, index) => {
                  if (
                    key != "Type_D'investissement" &&
                    key != "Date_previsionelle_fin_de_contrat" &&
                    key != "Prestation_Actuelle" &&
                    key != "Prestataire_Actuelle"
                  )
                    return (
                      <div className="custom-input-content" key={index}>
                        <input
                          type="text"
                          id="custom1"
                          placeholder="Titre"
                          className="modal-right-input custom-input-title"
                          defaultValue={key}
                        />
                        <input
                          type="text"
                          id="custom1"
                          placeholder="Valeur"
                          className="custom-input-value"
                          defaultValue={JSON.parse(value)[key]}
                        />
                      </div>
                    );
                })}

              {Array.from(Array(menuNumber)).map((e, i) => (
                <div className="custom-input-content" key={i}>
                  <input
                    type="text"
                    id="custom1"
                    placeholder="Titre"
                    className="modal-right-input custom-input-title"
                  />
                  <input
                    type="text"
                    id="custom1"
                    placeholder="Valeur"
                    className="custom-input-value"
                  />
                </div>
              ))}

              <div
                className="custom-add-message"
                onClick={() => setMenuNumber(menuNumber + 1)}
              >
                <img className="plus-icon" src={plus} alt="plus" />
                <p>Ajouter une valeur</p>
              </div>

              <div className="myModal-button">
                <div
                  className="save-button border-fullscreen border-gradient"
                  onClick={() => setValidation()}
                >
                  Enregistrer
                </div>
                <div className="cancel-button" onClick={() => closeModal()}>
                  Annuler
                </div>
              </div>
            </div>
          )}
          {validationActive && (
            <div className="myModal-content border-gradient border-l">
              <img className="myModal-img" src={valid} alt="checked" />
              <p className="myModal-title">Validé</p>
              <p className="myModal-description">Information Enregistrer</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default contractuelleModal;
