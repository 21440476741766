import React, { useEffect } from "react";
import valid from "../../../../assets/images/icon/modal_valid.png";
import axios from "axios";
import config from "../../../../config.json";
import "../modal.css";

import Point from "../../../../assets/images/navbar/point.png";
import SocialIcon from "../../../../assets/images/icon/social_icon.png";
import { toast } from "react-toastify";

const waitingModal = ({ setShowModal, setMessages, messages }) => {
  const [isActive, setIsActive] = React.useState(false);
  const [validationActive, setValidationActive] = React.useState(false);
  const [status, setStatus] = React.useState([]);
  const [data, setData] = React.useState({});

  const timestampToDDMMYYYYHHMM = (timestamp) => {
    const date = new Date(parseInt(timestamp));

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const paddedDay = day < 10 ? "0" + day : day;
    const paddedMonth = month < 10 ? "0" + month : month;
    const paddedHours = hours < 10 ? "0" + hours : hours;
    const paddedMinutes = minutes < 10 ? "0" + minutes : minutes;

    return `${paddedDay}/${paddedMonth}/${year} ${paddedHours}:${paddedMinutes}`;
  };

  useEffect(() => {
    axios
      .post(config.server_ip + "/api/opportunity/status", {
        token: localStorage.getItem("token"),
      })
      .then((response) => {
        setStatus(response.data.status);
      })
      .catch((error) => {});
  }, []);

  const showModal = (state) => {
    setIsActive(state);
  };

  const setValidation = () => {
    if (document.querySelector(".modal_textarea").value === "")
      return toast.error("Veuillez remplir tous les champs", {
        position: "bottom-right",
        theme: "dark",
      });
    if (document.querySelector(".modal_select").value === "null")
      return toast.error("Veuillez choisir un statut", {
        position: "bottom-right",
        theme: "dark",
      });
    const myData = {
      status: document.querySelector(".modal_select").value,
      comment: document.querySelector(".modal_textarea").value,
    };
    setValidationActive(true);
    setTimeout(() => {
      setMessages(myData);
      closeModal(true);
    }, 2000);
  };

  const closeModal = (needRefresh) => {
    const menu = document.getElementById("myModal");
    menu.classList.add("modal-fadeOut");
    setTimeout(() => {
      setIsActive(false);
      if (needRefresh) window.location.reload();
      setTimeout(() => {
        setValidationActive(false);
        menu.classList.remove("modal-fadeOut");
      }, 100);
    }, 200);
  };

  useEffect(() => {
    if (setShowModal.current === null) {
      setShowModal.current = showModal;
    }
  }, [setShowModal, showModal]);
  return (
    <div>
      {isActive && (
        <div id="myModal" className="myModal">
          {!validationActive && (
            <div className="myModal-content border-gradient border-l">
              <div className="myModal-close-container-2">
                <img
                  className="myModal-close"
                  src={Point}
                  alt="close"
                  onClick={() => closeModal()}
                />
              </div>
              <img className="myModal-img" src={SocialIcon} alt="checked" />
              <p className="myModal-title">Commenter</p>
              <p className="myModal-description">Commentaire</p>

              <div className="modal_message_box">
                {messages.length === 0 && <p>Aucun message</p>}
                {messages.map((message, index) => {
                  return (
                    <p key={index}>
                      {"<" + timestampToDDMMYYYYHHMM(message.date) + "> "}
                      {message.userId.username} {" => "} {message.text} | Status
                      : {message.statusId.name}
                    </p>
                  );
                })}
              </div>

              <div>
                <textarea
                  className="modal_textarea"
                  placeholder="Ecrire un commentaire..."
                ></textarea>
                <select className="modal_select">
                  <option value="null">Statut</option>
                  {status.map((status) => (
                    <option key={status._id} value={status._id}>
                      {status.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="myModal-button">
                <div
                  className="save-button border-fullscreen border-gradient"
                  onClick={() => setValidation()}
                >
                  Envoyer
                </div>
                <div className="cancel-button" onClick={() => closeModal()}>
                  Annuler
                </div>
              </div>
            </div>
          )}
          {validationActive && (
            <div className="myModal-content border-gradient border-l">
              <img className="myModal-img" src={valid} alt="checked" />
              <p className="myModal-title">Validé</p>
              <p className="myModal-description">Message envoyé</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default waitingModal;
