import React, { useEffect } from "react";
import axios from "axios";
import config from "../../../config.json";
import history from "../../../history";
import { useParams } from "react-router-dom";

const Join = () => {
  const [alreadyLoaded, setAlreadyLoaded] = React.useState(false);
  const [organisationName, setOrganisationName] = React.useState("");
  let { id } = useParams();

  useEffect(() => {
    const main = async () => {
      if (alreadyLoaded) {
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 5000);
        return;
      }
      const token = await localStorage.getItem("token");
      axios
        .post(config.server_ip + "/api/verify", { token })
        .then((response) => {})
        .catch((error) => {
          localStorage.clear();
          history.push("/");
        });

      axios
        .post(config.server_ip + "/api/invitation/join/" + id, { token })
        .then((response) => {
          setOrganisationName(response.data.organisationName);
        })
        .catch((error) => {});

      setAlreadyLoaded(true);
    };
    main();
  });

  return (
    <div className="prospect_background_component">
      <p>Vous avez rejoint l'organisation {organisationName}</p>
      <p>Redirection en cour...</p>
    </div>
  );
};

export default Join;
