import React, { useEffect } from "react";
import "./Home.css";
import Clock from "../../widgets/clock/Clock";
import axios from "axios";
import config from "../../../config.json";
import history from "../../../history";
import { ToastContainer, toast } from "react-toastify";

import valid from "../../../assets/images/icon/valid.png";
import unvalid from "../../../assets/images/icon/unvalid.png";
import target from "../../../assets/images/icon/target.png";
import relation from "../../../assets/images/icon/relation.png";
import espace from "../../../assets/images/icon/espace.png";
import document from "../../../assets/images/icon/document.png";
import gestion from "../../../assets/images/icon/intervention.png";

const Home = () => {
  const [organisation, setOrganisation] = React.useState(null);

  useEffect(() => {
    const main = async () => {
      const token = await localStorage.getItem("token");
      axios
        .post(config.server_ip + "/api/verify", { token })
        .then((response) => {})
        .catch((error) => {
          localStorage.removeItem("token");
          history.push("/");
        });

      if (!organisation)
        axios
          .post(config.server_ip + "/api/organisation/get", { token })
          .then(async (response) => {
            setOrganisation(response.data.organisations);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: "bottom-right",
              theme: "dark",
            });
          });
    };

    main();
  });

  const redirect = (redirect) => {
    history.push(redirect);
  };

  const unactive = () => {
    toast.error("Fonctionnalité non disponible", {
      position: "bottom-right",
      autoClose: 2000,
      toastId: "unvalid",
      theme: "dark",
    });
  };

  return (
    <div className="prospect_background_component loginComponent">
      <div className="header">
        <div className="header_right">
          <Clock unactive={true} />
        </div>
      </div>
      <div className="login">
        {organisation && organisation.length > 0 && (
          <div className="allCards">
            <div
              className="myCards border-gradient border-m"
              onClick={() => {
                redirect("/dashboard/prospect");
              }}
            >
              <div className="myCardsHeader">
                <img loading="lazy" src={valid} alt="valid" />
                <p>Activé</p>
              </div>
              <div className="myCardsMain">
                <img loading="lazy" src={target} alt="valid" />
              </div>
              <div className="myCardsFooter">
                <p>Gestion des opportunités</p>
              </div>
            </div>
            <div
              className="myCards border-gradient border-m"
              onClick={() => unactive()}
            >
              <div className="myCardsHeader">
                <img
                  loading="lazy"
                  src={unvalid}
                  alt="valid"
                  className="unvalid"
                />
                <p>Désactiver</p>
              </div>
              <div className="myCardsMain">
                <img
                  loading="lazy"
                  src={relation}
                  alt="valid"
                  className="relation"
                />
              </div>
              <div className="myCardsFooter">
                <p>Relation & gestion client</p>
              </div>
            </div>
            <div
              className="myCards border-gradient border-m"
              onClick={() => unactive()}
            >
              <div className="myCardsHeader">
                <img
                  loading="lazy"
                  src={unvalid}
                  alt="valid"
                  className="unvalid"
                />
                <p>Désactiver</p>
              </div>
              <div className="myCardsMain">
                <img
                  loading="lazy"
                  src={espace}
                  alt="valid"
                  className="espace"
                />
              </div>
              <div className="myCardsFooter">
                <p>Espace dédié client</p>
              </div>
            </div>
            <div
              className="myCards border-gradient border-m"
              onClick={() => unactive()}
            >
              <div className="myCardsHeader">
                <img
                  loading="lazy"
                  src={unvalid}
                  alt="valid"
                  className="unvalid"
                />
                <p>Désactiver</p>
              </div>
              <div className="myCardsMain">
                <img
                  loading="lazy"
                  src={document}
                  alt="valid"
                  className="document"
                />
              </div>
              <div className="myCardsFooter">
                <p>Gestion des documents</p>
              </div>
            </div>
            <div
              className="myCards border-gradient border-m"
              onClick={() => unactive()}
            >
              <div className="myCardsHeader">
                <img loading="lazy" src={unvalid} alt="valid" />
                <p>Désactiver</p>
              </div>
              <div className="myCardsMain">
                <img
                  loading="lazy"
                  src={gestion}
                  alt="valid"
                  className="devis"
                />
              </div>
              <div className="myCardsFooter">
                <p>Gestions des interventions</p>
              </div>
            </div>
          </div>
        )}

        {organisation && organisation.length === 0 && (
          <div className="testAccess">
            <p className="NoAccess">
              Désolé, mais ton compte ne fait pas partie de la version de test.
              Pour obtenir une invitation, veuillez contacter l'administrateur.
              Sinon, vous ne pourrez malheureusement pas accéder à cette
              fonctionnalité pour le moment.
            </p>
            <div
              className="lougoutButton"
              onClick={() => {
                localStorage.clear();
                history.push("/");
              }}
            >
              <p>Se déconnecter</p>
            </div>
          </div>
        )}
      </div>
      <ToastContainer autoClose={8000} />
    </div>
  );
};

export default Home;
