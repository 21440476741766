import React from "react";
import "./Feedback.css";
import config from "../../config.json";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const Feedback = () => {
  const toggleMenu = () => {
    const menu = document.getElementById("menu");
    if (menu.style.display === "block") {
      menu.style.display = "none";
    } else {
      menu.style.display = "block";
    }
  };

  const closeMenu = () => {
    const menu = document.getElementById("menu");
    menu.style.display = "none";
  };

  const sendBug = () => {
    const description = document.getElementById("description").value;
    if (description) {
      axios
        .post(config.server_ip + "/api/test/bug", { message: description })
        .then((response) => {
          toast.success("Bug signalé", {
            position: "bottom-right",
            theme: "dark",
          });

          closeMenu();
        })
        .catch((error) => {
          toast.error("Erreur lors de l'envoie du bug", {
            position: "bottom-right",
            theme: "dark",
          });
        });
    } else {
      toast.error("Veuillez remplir la description", {
        position: "bottom-right",
        theme: "dark",
      });
    }
  };

  return (
    <>
      <div id="menu" className="menu border-gradient border-s">
        <a href="#" className="close-btn" onClick={() => closeMenu()}>
          X
        </a>
        <h2>Signaler un bug</h2>
        <div className="feedbackForm">
          <label htmlFor="description">Description du bug</label>
          <textarea
            id="description"
            name="description"
            placeholder="Faite une description pour expliquer le bug"
          ></textarea>
        </div>
        <button className="SendButton" onClick={() => sendBug()}>
          Envoyer
        </button>
      </div>
      <div id="bug-report" className="bug-report" onClick={() => toggleMenu()}>
        <span>!</span>
      </div>
      <ToastContainer autoClose={8000} />
    </>
  );
};

export default Feedback;
