import React, { useEffect, useState } from "react";
import "./Register.css";
import Clock from "../../widgets/clock/Clock";
import history from "../../../history";
import axios from "axios";
import config from "../../../config.json";
import { ToastContainer, toast } from "react-toastify";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      history.push("/dashboard");
    }
  });

  const handleEnterPress = (e) => {
    if (!e) return;
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(config.server_ip + "/api/register", {
        email,
        password,
        username: name + " " + surname,
      })
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        history.push("/dashboard");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: "bottom-right",
          theme: "dark",
        });
      });
  };

  return (
    <div
      className="prospect_background_component loginComponent"
      onKeyDown={(e) => handleEnterPress(e)}
      tabIndex={0}
    >
      <div className="header">
        <div className="header_right">
          <Clock unactive={true} />
        </div>
      </div>
      <div className="login">
        <div className="mainLogin border-gradient border-xxl">
          <h3>S’inscrire</h3>
          <div>
            <input
              className="login_demi_input"
              type="text"
              placeholder="Nom"
              style={{ marginRight: "10px" }}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              className="login_demi_input"
              type="text"
              placeholder="Prénom"
              style={{ marginLeft: "10px" }}
              onChange={(e) => setSurname(e.target.value)}
            />
          </div>
          <input
            className="login_input"
            type="text"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="login_input"
            type="password"
            placeholder="Mot de passe"
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="buttonContain">
            <button
              className="register_button"
              onClick={() => history.push("/login")}
            >
              Se connecté
            </button>
            <button
              className="login_button"
              onClick={(e) => handleSubmit(e)}
              onKeyDown={(e) => handleEnterPress(e)}
            >
              S'INSCRIRE
            </button>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={8000} />
    </div>
  );
};

export default Register;
