import React, { useEffect, useState } from "react";
import "../login/Login.css";
import Clock from "../../widgets/clock/Clock";
import history from "../../../history";
import axios from "axios";
import config from "../../../config.json";
import { ToastContainer, toast } from "react-toastify";

const ResetPass = () => {
  const [password, setPassword] = useState("");
  const [passwordVerif, setPasswordVerif] = useState("");
  const url = new URL(window.location);
  const resetToken = url.searchParams.get("token");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      return history.push("/dashboard");
    }

    axios
      .post(config.server_ip + "/api/verifyResetToken", { token: resetToken })
      .then((response) => {
        return;
      })
      .catch((error) => {
        return history.push("/login");
      });
  });

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(config.server_ip + "/api/resetPass", {
        token: resetToken,
        password,
        passwordVerif,
      })
      .then((response) => {
        toast.success(response.data.message, {
          position: "bottom-right",
          theme: "dark",
        });
        setTimeout(() => {
          history.push("/login");
        }, 2000);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: "bottom-right",
          theme: "dark",
        });
      });
  };

  return (
    <div
      className="prospect_background_component loginComponent"
      onKeyDown={(e) => handleEnterPress(e)}
      tabIndex={0}
    >
      <div className="header">
        <div className="header_right">
          <Clock unactive={true} />
        </div>
      </div>
      <div className="login">
        <div className="mainLogin border-gradient border-xl">
          <h3>Changement de mot de passe</h3>
          <input
            className="login_input"
            type="password"
            placeholder="Nouveau mot de passe"
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            className="login_input"
            type="password"
            placeholder="Confirmer nouveau mot de passe"
            onChange={(e) => setPasswordVerif(e.target.value)}
          />
          <div
            className="buttonContain"
            style={{ justifyContent: "flex-end", marginTop: "10px" }}
          >
            <button className="login_button" onClick={(e) => handleSubmit(e)}>
              Enregistrer
            </button>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={8000} />
    </div>
  );
};

export default ResetPass;
