import React, { useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import config from "../../config.json";
import history from "../../history";
import "./navbar.css";
import messages from "../../message.json";

import ExcelModal from "../widgets/modal/importExcelModal/ImportExcelModal";
import NewOpportunityModal from "../widgets/modal/newOpportunity/newOpportunity";

import DefaultIcon from "../../assets/images/icon/default_icon.png";
import Opportunite from "../../assets/images/navbar/opportunite.png";
import Prospection from "../../assets/images/navbar/prospection.png";
import Gestion from "../../assets/images/navbar/gestion.png";
import Profil from "../../assets/images/navbar/profil.png";
import Settings from "../../assets/images/navbar/settings.png";
import Home from "../../assets/images/navbar/home.png";

import HomeSelected from "../../assets/images/navbar/selected/home.png";

import Point from "../../assets/images/navbar/point.png";

function Navbar(props) {
  const excelModalFunction = useRef(null);
  const newOpportunityModalFunction = useRef(null);

  const setNewOpportunity = (data) => {
    const token = localStorage.getItem("token");
    const organisationId = localStorage.getItem("CurrentOrganisation");
    axios
      .post(config.server_ip + "/api/opportunity/addone", {
        token,
        data,
        organisationId,
      })
      .then((response) => {
        window.location.reload();
      });
  };

  const setExcelModal = (state) => {
    if (excelModalFunction.current) {
      excelModalFunction.current(state);
    }
  };

  const setNewOpportunityModal = (state) => {
    if (newOpportunityModalFunction.current) {
      newOpportunityModalFunction.current(state);
    }
  };

  const handleChangeOrganisation = (organisationId) => {
    localStorage.setItem("CurrentOrganisation", organisationId);
    props.setOrga(organisationId);
  };

  const openMenu = (menuId) => {
    const allMenu = document.getElementsByClassName("drop");
    const menu = document.getElementById(menuId);

    if (menu.style.display === "block") return closeMenu(menuId);

    for (let i = 0; i < allMenu.length; i++)
      if (allMenu[i].id !== menuId) closeMenu(allMenu[i].id);

    menu.style.display = "block";
  };

  const closeMenu = (menuId) => {
    const menu = document.getElementById(menuId);
    menu.classList.add("fadeOut");
    setTimeout(() => {
      menu.style.display = "none";
      setTimeout(() => {
        menu.classList.remove("fadeOut");
      }, 100);
    }, 200);
  };

  useEffect(() => {
    const main = async () => {
      const token = await localStorage.getItem("token");
      axios
        .post(config.server_ip + "/api/verify", {
          token,
        })
        .then((response) => {})
        .catch((error) => {
          localStorage.clear();
          history.push("/login");
        });
    };

    main();
  }, []);

  const redirect = (redirect) => {
    history.push(redirect);
  };

  const body = document.querySelector("body");
  body.addEventListener("click", (e) => {
    if (e.target.classList.contains("dontClose")) return;

    const allMenu = document.getElementsByClassName("drop");
    for (let i = 0; i < allMenu.length; i++) {
      if (allMenu[i].style.display === "block") {
        if (e.target.className === "drop") closeMenu(allMenu[i].id);
        else if (!allMenu[i].contains(e.target)) closeMenu(allMenu[i].id);
      }
    }
  });

  return (
    <>
      <div className="mainNavbar border-gradient border-m">
        <div className="FirstContainer defaultContainer">
          <div
            className="organisation dontClose"
            onClick={() => openMenu("orga")}
          >
            <img src={DefaultIcon} alt="logo" className="dontClose" />
            <p className="dontClose">{props?.currentOrganisation[0]?.name}</p>
          </div>
          <div
            className={
              props.selected === "Home" ? "component selected" : "component"
            }
            onClick={() => history.push("/dashboard/prospect")}
          >
            <img
              src={props.selected === "Home" ? HomeSelected : Home}
              className={
                props.selected === "Home" ? "house selectedImage" : "house"
              }
              alt="logo"
            />
            <p className={props.selected === "Home" ? "selected" : ""}>
              {messages.nav.home}
            </p>
          </div>
          <div className="search">
            <input
              className="searchbar"
              type="text"
              placeholder={messages.nav.search}
            />
          </div>
        </div>

        <div className="SecondContainer defaultContainer">
          <div
            className="component dontClose"
            onClick={() => openMenu("opportunite")}
          >
            <img
              className="opportuniteImage dontClose"
              src={Opportunite}
              alt="logo"
            />
            <p className="dontClose">{messages.nav.opportunity}</p>
          </div>
          <div className="component dontClose" onClick={() => openMenu("pros")}>
            <img
              className="campagneImage dontClose "
              src={Prospection}
              alt="logo"
            />
            <p className="dontClose">{messages.nav.campaign}</p>
          </div>
          <div className="component dontClose">
            <img className="touneeImage dontClose" src={Gestion} alt="logo" />
            <p className="dontClose">{messages.nav.tour_management}</p>
          </div>
        </div>

        <div className="ThirdContainer defaultContainer">
          <div className="component dontClose">
            <img className="compteImage dontClose" src={Profil} alt="logo" />
            <p className="dontClose">{messages.nav.account}</p>
          </div>
          <div
            className="component dontClose"
            onClick={() => openMenu("settings")}
          >
            <img
              className="settingsImage dontClose"
              src={Settings}
              alt="logo"
            />
            <p className="dontClose">{messages.nav.settings}</p>
          </div>
        </div>
      </div>

      {/* DropDown */}

      {/* Organisation */}
      <div
        id="orga"
        className="drop_organisation drop  border-gradient border-m"
      >
        <img
          className="close"
          src={Point}
          alt="logo"
          onClick={() => closeMenu("orga")}
        />
        <div className="drop_header">
          <img src={DefaultIcon} alt="logo" />
          <p>{props?.currentOrganisation[0]?.name}</p>
        </div>
        <div className="drop_content">
          {props?.allOrganisation?.map((organisation) => {
            if (organisation._id === props?.currentOrganisation[0]?._id)
              return null;
            return (
              <div
                className="myOrga"
                key={organisation._id}
                onClick={handleChangeOrganisation.bind(this, organisation._id)}
              >
                <img src={DefaultIcon} alt="logo" />
                <p>{organisation.name}</p>
              </div>
            );
          })}
        </div>
      </div>

      {/* Prospection */}
      <div
        id="pros"
        className="drop_prospection drop  border-gradient border-m"
      >
        <img
          className="close"
          src={Point}
          alt="logo"
          onClick={() => closeMenu("pros")}
        />
        <p onClick={() => redirect("/dashboard/prospect/list")}>
          {messages.nav.campaign_sub.campaign_prospection}
        </p>
        <p onClick={() => setExcelModal(true)}>
          {messages.nav.campaign_sub.import_campaign}
        </p>
        <p onClick={() => setNewOpportunityModal(true)}>
          {messages.nav.campaign_sub.import_prospect}
        </p>
      </div>

      {/* Opportunity */}
      <div
        id="opportunite"
        className="drop_opportunite drop border-gradient border-m"
      >
        <img
          className="close"
          src={Point}
          alt="logo"
          onClick={() => closeMenu("opportunite")}
        />
        <p onClick={() => redirect("/dashboard/opportunity/list/all")}>
          {messages.nav.opportunity_sub.all}
        </p>
        <p onClick={() => redirect("/dashboard/opportunity/list/todo")}>
          {messages.nav.opportunity_sub.todo}
        </p>
        <p onClick={() => redirect("/dashboard/opportunity/list/waiting")}>
          {messages.nav.opportunity_sub.waiting}
        </p>
        <p onClick={() => redirect("/dashboard/opportunity/list/loose")}>
          {messages.nav.opportunity_sub.loose}
        </p>
        <p onClick={() => redirect("/dashboard/opportunity/list/win")}>
          {messages.nav.opportunity_sub.win}
        </p>
        <p>{messages.nav.opportunity_sub.no_assigned}</p>
      </div>

      {/* Settings */}
      <div
        id="settings"
        className="drop_settings drop  border-gradient border-m"
      >
        <img
          className="close"
          src={Point}
          alt="logo"
          onClick={() => closeMenu("settings")}
        />
        <p
          onClick={() => {
            localStorage.clear();
            history.push("/login");
          }}
        >
          {messages.nav.settings_sub.logout}
        </p>
      </div>

      <ToastContainer autoClose={8000} />
      <ExcelModal setShowModal={excelModalFunction} toast={toast} />
      <NewOpportunityModal
        setShowModal={newOpportunityModalFunction}
        toast={toast}
        setNewOpportunity={setNewOpportunity}
      />
    </>
  );
}

export default Navbar;
