import React, { useRef, useState, useEffect } from "react";
import mapboxgl from "!mapbox-gl";
import "./Maps.css";

mapboxgl.accessToken =
  "pk.eyJ1IjoiYmxpdHpmdWxsIiwiYSI6ImNreDU0N2YwbDIxaGUyeXB6b2prMzZqcWkifQ.emI2Z1eOOr-nMT5l7JgKKg";

const myMap = (props) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(
    props?.latlng ? parseFloat(props.latlng.split("/")[1]) : -1.684891360399767
  );
  const [lat, setLat] = useState(
    props?.latlng ? parseFloat(props.latlng.split("/")[0]) : 48.117266
  );
  const [zoom, setZoom] = useState(16);

  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/dark-v11",
      center: [lng, lat],
      zoom: zoom,
    });
  });

  return (
    <div>
      <div
        style={{
          height: props.height,
          width: props.width,
          marginTop: props?.mtop ? props.mtop : "0",
          borderRadius: props?.borderRadius ? props.borderRadius : "0",
          overflow: "hidden",
        }}
        ref={mapContainer}
        className="map-container"
      />
    </div>
  );
};

export default myMap;
