import React, { useEffect, useRef } from "react";
import axios from "axios";
import config from "../../../../config.json";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import messages from "../../../../message.json";
import history from "../../../../history.js";

import "./OpportunityDetails.css";
import "./OpportunityDetails_V2.css";
import "react-toastify/dist/ReactToastify.css";

import loader from "../../../../assets/images/icon/loader.png";
import Wait from "../../../../assets/images/icon/waiting.png";
import Win from "../../../../assets/images/icon/win.png";
import Loose from "../../../../assets/images/icon/trash.png";
import EditPen from "../../../../assets/images/icon/edit_pen.png";
import Mail from "../../../../assets/images/icon/email.png";
import GreenPhone from "../../../../assets/images/icon/phone_green.png";
import RedPhone from "../../../../assets/images/icon/phone_red.png";
import Car from "../../../../assets/images/icon/car.png";
import SendMessage from "../../../../assets/images/icon/send_message.png";

import { arrow as Arrowsvg } from "../../../../assets/svg/svg.js";

import Navbar from "../../../navbar/navbar";
import Clock from "../../../widgets/clock/Clock";
import LooseModal from "../../../widgets/modal/looseModal/looseModal";
import WaitModal from "../../../widgets/modal/waitingModal/waitingModal";
import TodoModal from "../../../widgets/modal/todoModal/todoModal";
import ContractuelleModal from "../../../widgets/modal/contractuelleModal/contractuelleModal";
import EditProspectModal from "../../../widgets/modal/editProspectModal/editProspectModal";
import MessageModal from "../../../widgets/modal/sendMessageModal/sendMessageModal";

const OpportunityDetails = () => {
  const { id } = useParams();
  const looseModalFunction = useRef(null);
  const waitModalFunction = useRef(null);
  const todoModalFunction = useRef(null);
  const contractuelleModalFunction = useRef(null);
  const editProspectModalFunction = useRef(null);
  const sendMessageModalFunction = useRef(null);
  const [alreadyLoaded, setAlreadyLoaded] = React.useState(false);
  const [opportunity, setOpportunity] = React.useState(null);
  const [organisation, setOrganisation] = React.useState([]);
  const [currentOrganisation, setCurrentOrganisation] = React.useState("");
  const [thisMonth, setThisMonth] = React.useState([]);
  const [lastMonth, setLastMonth] = React.useState([]);
  const [other, setOther] = React.useState([]);
  const [selectedStatus, setSelectedStatus] = React.useState("");
  const [allStatus, setAllStatus] = React.useState(null);
  const queryParameters = new URLSearchParams(window.location.search);
  const type = queryParameters.get("type") || null;
  let opportunityList;
  try {
    if (type == null) opportunityList = [];
    else opportunityList = localStorage.getItem(type).split(";");
  } catch (e) {
    opportunityList = [];
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    const main = async () => {
      if (alreadyLoaded) return;
      const token = await localStorage.getItem("token");

      if (!allStatus)
        axios
          .post(config.server_ip + "/api/opportunity/status", { token })
          .then((response) => {
            const mail = response.data.status.find(
              (status) => status.name === "Mail"
            );
            const AA = response.data.status.find(
              (status) => status.name === "Appel Abouti"
            );
            const ANA = response.data.status.find(
              (status) => status.name === "Appel non Abouti"
            );
            const PP = response.data.status.find(
              (status) => status.name === "PP"
            );

            setAllStatus({
              mail: mail._id,
              AA: AA._id,
              ANA: ANA._id,
              PP: PP._id,
            });
          });

      if (organisation.length === 0)
        axios
          .post(config.server_ip + "/api/organisation/get", { token })
          .then(async (response) => {
            if (response.data.organisations.length == 0)
              history.push("/dashboard");
            setOrganisation(response.data.organisations);
            if (
              (await localStorage.getItem("CurrentOrganisation")) == undefined
            ) {
              localStorage.setItem(
                "CurrentOrganisation",
                response.data.organisations[0]._id
              );
              setCurrentOrganisation(response.data.organisations[0]._id);
            } else {
              setCurrentOrganisation(
                await localStorage.getItem("CurrentOrganisation")
              );
            }
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: "bottom-right",
              theme: "dark",
            });
          });

      axios
        .post(config.server_ip + "/api/opportunity/get", {
          token,
          opportunityId: id,
          organisationId: await localStorage.getItem("CurrentOrganisation"),
        })
        .then(async (response) => {
          if (response.data.opportunity.message.length > 0) {
            const _thisMonth = [];
            const _lastMonth = [];
            const _other = [];
            const now = new Date();
            const currentMonth = now.getMonth();
            const currentYear = now.getFullYear();
            const promises = response.data.opportunity.message.map(
              (message) => {
                const messageDate = new Date(parseInt(message.date));
                const messageMonth = messageDate.getMonth();
                const messageYear = messageDate.getFullYear();

                if (
                  messageYear === currentYear &&
                  messageMonth === currentMonth
                ) {
                  _thisMonth.push(message);
                } else if (
                  messageYear === currentYear &&
                  messageMonth === currentMonth - 1
                ) {
                  _lastMonth.push(message);
                } else {
                  _other.push(message);
                }
              }
            );

            Promise.all(promises).then(() => {
              setThisMonth(_thisMonth);
              setLastMonth(_lastMonth);
              setOther(_other);
            });
          }

          if (!response.data.opportunity.prospectId.latlng) {
            axios
              .post(config.server_ip + "/api/prospect/setLatLng", {
                token,
                prospectId: response.data.opportunity.prospectId._id,
                organisationId: await localStorage.getItem(
                  "CurrentOrganisation"
                ),
              })
              .then((response) => {
                window.location.reload();
              })
              .catch((error) => {
                toast.error(error?.response?.data?.message, {
                  position: "bottom-right",
                  theme: "dark",
                });
              });
          }

          setOpportunity(response.data.opportunity);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message, {
            position: "bottom-right",
            theme: "dark",
          });
        });

      setAlreadyLoaded(true);

      const dateWait = document.getElementById("dateWait");
      const date = document.getElementById("date");
      if (dateWait && date) {
        const currentDate = new Date();
        currentDate.setHours(currentDate.getHours() + 2);
        const formattedDate = currentDate.toISOString().slice(0, 16);
        dateWait.value = formattedDate;
        date.value = formattedDate;
      }
    };

    main();

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    alreadyLoaded,
    currentOrganisation,
    organisation,
    id,
    thisMonth,
    lastMonth,
    other,
  ]);

  const getPositionInList = () => {
    let position = 0;
    opportunityList.forEach((opportunityId, index) => {
      if (opportunityId === id) position = index;
    });

    return position;
  };

  const isLastPosition = () => {
    const position = getPositionInList();
    return position === opportunityList.length - 1;
  };

  const isFirstPosition = () => {
    const position = getPositionInList();
    return position === 0;
  };

  const moveLeft = () => {
    if (isFirstPosition()) return;

    const position = getPositionInList();
    const previousOpportunityId = opportunityList[position - 1];
    history.push(
      `/dashboard/opportunity/${previousOpportunityId}?type=${type}`
    );
  };

  const moveRight = () => {
    if (isLastPosition()) return;

    const position = getPositionInList();
    const nextOpportunityId = opportunityList[position + 1];
    history.push(`/dashboard/opportunity/${nextOpportunityId}?type=${type}`);
  };

  const handleKeyDown = (e) => {
    if (!opportunityList || opportunityList.length == 0) return;
    if (event.key === "ArrowLeft") {
      moveLeft();
    } else if (event.key === "ArrowRight") {
      moveRight();
    }
  };

  const timestampToDDMMYYYY = (timestamp) => {
    const date = new Date(parseInt(timestamp));

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const paddedDay = day < 10 ? "0" + day : day;
    const paddedMonth = month < 10 ? "0" + month : month;

    return `${paddedDay}/${paddedMonth}/${year}`;
  };

  const contractuelleHandler = async (data) => {
    axios
      .post(config.server_ip + "/api/opportunity/updateContractuelle", {
        token: await localStorage.getItem("token"),
        opportunityId: id,
        organisationId: await localStorage.getItem("CurrentOrganisation"),
        data: data,
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: "bottom-right",
          theme: "dark",
        });
      });
  };

  const sendMessageHandler = async () => {
    const selectedStatus = document.querySelector(".details-chat-selected");

    if (!selectedStatus)
      return toast.error("Veuillez selectionner un status", {
        position: "bottom-right",
        theme: "dark",
      });

    const status = selectedStatus.id;
    const comment = document.getElementById("comment").value;

    if (comment.length < 1)
      return toast.error("Le commentaire doit contenir au moins 1 caractères", {
        position: "bottom-right",
        theme: "dark",
      });

    messageHandler({ comment, status });
  };

  const openMap = () => {
    const latlng = opportunity?.prospectId?.latlng;
    if (latlng) {
      const [lat, lng] = latlng.split("/");
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`,
        "_blank"
      );
    }
  };

  const waitingHandler = async () => {
    const date = document.getElementById("dateWait").value;
    if (date === "")
      return toast.error("Veuillez renseigner une date", {
        position: "bottom-right",
        theme: "dark",
      });
    const timestamp = new Date(date).getTime();
    const token = await localStorage.getItem("token");
    axios
      .post(config.server_ip + "/api/opportunity/setWaiting", {
        token,
        opportunityId: id,
        organisationId: await localStorage.getItem("CurrentOrganisation"),
        date: timestamp,
      })
      .then(async (response) => {})
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: "bottom-right",
          theme: "dark",
        });
      });
  };

  const todoHandler = async () => {
    const token = await localStorage.getItem("token");
    axios
      .post(config.server_ip + "/api/opportunity/setTodo", {
        token,
        opportunityId: id,
        organisationId: await localStorage.getItem("CurrentOrganisation"),
      })
      .then(async (response) => {})
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: "bottom-right",
          theme: "dark",
        });
      });
  };

  const winHandler = async () => {
    const token = await localStorage.getItem("token");
    axios
      .post(config.server_ip + "/api/opportunity/setWin", {
        token,
        opportunityId: id,
        organisationId: await localStorage.getItem("CurrentOrganisation"),
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: "bottom-right",
          theme: "dark",
        });
      });
  };

  const looseHandler = async () => {
    const token = await localStorage.getItem("token");
    axios
      .post(config.server_ip + "/api/opportunity/setLoose", {
        token,
        opportunityId: id,
        organisationId: await localStorage.getItem("CurrentOrganisation"),
      })
      .then((response) => {})
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: "bottom-right",
          theme: "dark",
        });
      });
  };

  const messageHandler = async (data) => {
    const token = await localStorage.getItem("token");
    axios
      .post(config.server_ip + "/api/messages/post", {
        token,
        opportunityId: id,
        organisationId: await localStorage.getItem("CurrentOrganisation"),
        message: data.comment,
        statusId: data.status,
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {});
  };

  const waitHandler = async () => waitingHandler();

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const editProspectHandler = async (data) => {
    axios
      .post(config.server_ip + "/api/opportunity/updateProspect", {
        token: await localStorage.getItem("token"),
        opportunityId: id,
        organisationId: await localStorage.getItem("CurrentOrganisation"),
        data: data,
      })
      .then((response) => {
        window.location.reload();
      });
  };

  const selectStatusHandler = (e) => {
    const allStatus = document.querySelectorAll(".details-chat-status");
    allStatus.forEach((status) => {
      status.classList.remove("details-chat-selected");
    });
    e.target.classList.add("details-chat-selected");
  };

  const setLooseModal = (state) => {
    if (looseModalFunction.current) {
      looseModalFunction.current(state);
    }
  };

  const setContractuelleModal = (state) => {
    if (contractuelleModalFunction.current) {
      contractuelleModalFunction.current(state);
    }
  };

  const setEditProspectModal = (state) => {
    if (editProspectModalFunction.current) {
      editProspectModalFunction.current(state);
    }
  };

  const setMessageModal = (state) => {
    if (sendMessageModalFunction.current) {
      sendMessageModalFunction.current(state);
    }
  };

  const setWaitingModal = (state) => {
    const date = document.getElementById("dateWait").value;
    if (date === "")
      return toast.error("Veuillez renseigner une date", {
        position: "bottom-right",
        theme: "dark",
      });
    if (waitModalFunction.current) {
      waitModalFunction.current(state);
    }
  };

  const setTodoModal = (state) => {
    if (todoModalFunction.current) {
      todoModalFunction.current(state);
    }
  };

  const setOpportunityToTODO = async () => {
    const token = await localStorage.getItem("token");
    axios
      .post(config.server_ip + "/api/opportunity/setTodo", {
        token,
        opportunityId: id,
        organisationId: await localStorage.getItem("CurrentOrganisation"),
      })
      .then(async (response) => {
        window.location.reload();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: "bottom-right",
          theme: "dark",
        });
      });
  };

  const openMenu = (id, type, state) => {
    closeAllMenu(id);
    const menu = document.getElementById(id);
    console.log(menu);
    const button = document.getElementById(id + "-button");
    if (menu.style.display === "none") {
      menu.style.display = "flex";
      button.classList.remove("border-gradient");
      button.classList.add("active-" + type);
      button.classList.add("border-" + type);
      button.classList.add(type + "-menu-button");
    } else {
      menu.style.display = "none";
      if (state === opportunity.state) return;
      button.classList.remove("border-" + type);
      button.classList.remove("active-" + type);
      button.classList.add("border-gradient");
    }
  };

  const closeAllMenu = (selected) => {
    const menus = [
      ["todo-menu", "TODO", "todo"],
      ["wait-menu", "WAIT", "wait"],
      ["win-menu", "WIN", "win"],
      ["loose-menu", "LOOSE", "loose"],
    ];

    menus.forEach((id) => {
      if (id[0] === selected) return;
      const menu = document.getElementById(id[0]);
      const button = document.getElementById(id[0] + "-button");

      console.log(id);
      menu.style.display = "none";
      if (id[1] === opportunity.state) return;
      button.classList.remove("border-" + id[2]);
      button.classList.remove("active-" + id[2]);
      button.classList.add("border-gradient");
    });
  };

  return (
    <div className="prospect_background_component">
      <Navbar
        currentOrganisation={organisation.filter(
          (o) => o["_id"] === currentOrganisation
        )}
        selected="Home"
        allOrganisation={organisation}
        setOrga={(organisationId) => {
          setCurrentOrganisation(organisationId);
          setAlreadyLoaded(false);
        }}
      />
      <div className="main">
        <div className="main_header">
          <Clock />
        </div>
        {alreadyLoaded && opportunity && (
          <div className="myMain-component">
            {!isFirstPosition() && (
              <span className="arrowSVGLeft" onClick={() => moveLeft()}>
                <Arrowsvg />
              </span>
            )}

            <div className="myMain border-gradient border-fullscreen myMainV2">
              <div className="details_header">
                <div>
                  <div className="details-title border-gradient border-l">
                    <p className="selectable">
                      {opportunity?.myUser?.username || "Aucun"} |{" "}
                      {opportunity?.prospectId?.prospect || "Aucun"}
                    </p>
                  </div>
                </div>
                <div className="details-button-holder">
                  <div>
                    <div
                      onClick={() => openMenu("todo-menu", "todo", "TODO")}
                      id="todo-menu-button"
                      className={
                        "details-action-button border-fullscreen border-todo-hover todo-menu-button " +
                        (opportunity.state == "TODO"
                          ? "border-todo active-todo"
                          : "border-gradient")
                      }
                    >
                      <p>{messages.opportunity_details.status.todo}</p>
                      <img
                        src={Wait}
                        alt="logo"
                        style={{ width: "9px", height: "12px" }}
                      />
                    </div>
                    <div
                      style={{ display: "none" }}
                      id="todo-menu"
                      className={
                        "details-sous-menu border-s border-todo active-todo"
                      }
                    >
                      <input type="datetime-local" id="dateTodo" />
                      <div
                        className="details-sous-menu-button border-fullscreen border-gradient"
                        onClick={() => setOpportunityToTODO()}
                      >
                        <p>{messages.global.valid}</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      id="wait-menu-button"
                      onClick={() => openMenu("wait-menu", "wait", "WAITING")}
                      className={
                        "details-action-button border-fullscreen border-wait-hover wait-menu-button " +
                        (opportunity.state == "WAITING"
                          ? "border-wait active-wait"
                          : "border-gradient")
                      }
                    >
                      <p>{messages.opportunity_details.status.waiting}</p>
                      <img
                        src={Wait}
                        alt="logo"
                        style={{ width: "9px", height: "12px" }}
                      />
                    </div>
                    <div
                      style={{ display: "none" }}
                      id="wait-menu"
                      className={
                        "details-sous-menu border-s border-wait active-wait"
                      }
                    >
                      <input type="datetime-local" id="dateWait" />
                      <div
                        className="details-sous-menu-button border-fullscreen border-gradient"
                        onClick={() => setWaitingModal(true)}
                      >
                        <p>{messages.global.valid}</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      onClick={() => openMenu("win-menu", "win", "WIN")}
                      id="win-menu-button"
                      className={
                        "details-action-button border-fullscreen border-win-hover win-menu-button " +
                        (opportunity.state == "WIN"
                          ? "border-win active-win"
                          : "border-gradient")
                      }
                    >
                      <p>{messages.opportunity_details.status.win}</p>
                      <img
                        src={Win}
                        alt="logo"
                        style={{ width: "12px", height: "12px" }}
                      />
                    </div>
                    <div
                      style={{ display: "none" }}
                      id="win-menu"
                      className={
                        "details-sous-menu border-s border-win active-win"
                      }
                    >
                      <input type="datetime-local" id="dateWin" />
                      <div
                        className="details-sous-menu-button border-fullscreen border-gradient"
                        onClick={() => winHandler()}
                      >
                        <p>{messages.global.valid}</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      onClick={() => openMenu("loose-menu", "loose", "LOOSE")}
                      id="loose-menu-button"
                      className={
                        "details-action-button border-fullscreen border-loose-hover loose-menu-button " +
                        (opportunity.state == "LOOSE"
                          ? "border-loose active-loose"
                          : "border-gradient")
                      }
                    >
                      <p>{messages.opportunity_details.status.loose}</p>
                      <img
                        src={Loose}
                        alt="logo"
                        style={{ width: "11px", height: "12px" }}
                      />
                    </div>
                    <div
                      style={{ display: "none" }}
                      id="loose-menu"
                      className={
                        "details-sous-menu border-s border-loose active-loose"
                      }
                    >
                      <div
                        className="details-sous-menu-button border-fullscreen border-gradient"
                        onClick={() => setLooseModal(true)}
                      >
                        <p>{messages.global.valid}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="details-subtitles">
                {messages.opportunity_details.fiche_name}
              </p>
              <div className="details-main">
                <div className="details-left">
                  <h3>{messages.opportunity_details.information}</h3>
                  <div
                    id="details-information"
                    className="details-information selectable"
                  >
                    <p>
                      {messages.global.prospect} :{" "}
                      {opportunity?.prospectId?.prospect
                        ? opportunity.prospectId.prospect
                        : messages.global.any}
                    </p>
                    <p>
                      {messages.global.enseigne} :{" "}
                      {opportunity?.prospectId?.enseigne
                        ? opportunity.prospectId.enseigne
                        : messages.global.any}
                    </p>
                    <p>
                      {messages.global.codepostale} :{" "}
                      {opportunity?.prospectId?.codePostale
                        ? opportunity.prospectId.codePostale
                        : messages.global.any}
                    </p>
                    <p>
                      {messages.global.adresse_complete} :{" "}
                      {opportunity?.prospectId?.adresseComplete
                        ? opportunity.prospectId.adresseComplete
                        : messages.global.any}
                    </p>
                    <p>
                      {messages.global.ville} :{" "}
                      {opportunity?.prospectId?.ville
                        ? opportunity.prospectId.ville
                        : messages.global.any}
                    </p>
                    <p>
                      {messages.global.site} :{" "}
                      {opportunity?.prospectId?.site
                        ? opportunity.prospectId.site
                        : messages.global.any}
                    </p>
                    <p>
                      {messages.global.phone} :{" "}
                      {opportunity?.prospectId?.telephone
                        ? opportunity.prospectId.telephone
                        : messages.global.any}
                    </p>
                    <p>
                      {messages.global.email} :{" "}
                      {opportunity?.prospectId?.eMail
                        ? opportunity.prospectId.eMail
                        : messages.global.any}
                    </p>
                    <p>
                      {messages.global.siret} :{" "}
                      {opportunity?.prospectId?.siret
                        ? opportunity.prospectId.siret
                        : messages.global.any}
                    </p>
                    <p>
                      {messages.global.libelle_activity} :{" "}
                      {opportunity?.prospectId?.libelleactivite
                        ? opportunity.prospectId.libelleactivite
                        : messages.global.any}
                    </p>
                    <p>
                      {messages.global.salaries_tranche} :{" "}
                      {opportunity?.prospectId?.trancheSalarier
                        ? opportunity.prospectId.trancheSalarier
                        : messages.global.any}
                    </p>
                    {opportunity?.prospectId?.value !== undefined &&
                      Object.keys(
                        JSON.parse(opportunity?.prospectId?.value)
                      ).map((key) => {
                        const allKey = opportunity.excel;
                        if (
                          key == allKey?.adresseComplete ||
                          key == allKey?.codePostale ||
                          key == allKey?.eMail ||
                          key == allKey?.enseigne ||
                          key == allKey?.libelleactivite ||
                          key == allKey?.prospect ||
                          key == allKey?.siret ||
                          key == allKey?.site ||
                          key == allKey?.telephone ||
                          key == allKey?.trancheSalarier ||
                          key == allKey?.ville
                        )
                          return;
                        if (key === "contact") {
                          const contacts = JSON.parse(
                            opportunity?.prospectId?.value
                          )[key];

                          const contactElements = contacts.map(
                            (contact, index) => {
                              return Object.keys(contact).map((contactKey) => {
                                return (
                                  <p key={`${index}-${contactKey}`}>
                                    {messages.global.contact}
                                    {index + 1} | {contactKey} :{" "}
                                    {contact[contactKey] === undefined
                                      ? "Aucun"
                                      : contact[contactKey]}
                                  </p>
                                );
                              });
                            }
                          );

                          return <>{contactElements}</>;
                        } else if (
                          JSON.parse(opportunity?.prospectId?.value)[key] ==
                          undefined
                        )
                          return;
                        else
                          return (
                            <p key={key}>
                              {key} :{" "}
                              {JSON.parse(opportunity?.prospectId?.value)[key]}
                            </p>
                          );
                      })}
                  </div>
                  <div className="details-information-footer">
                    <p>
                      {messages.opportunity_details.file}
                      <span className="details-files">
                        {messages.opportunity_details.no_file}
                      </span>
                    </p>
                    <div
                      className="editPen"
                      onClick={() => setEditProspectModal(true)}
                    >
                      <p>{messages.global.edit}</p>
                      <img
                        src={EditPen}
                        alt="logo"
                        style={{ width: "12px", height: "14px" }}
                      />
                    </div>
                  </div>
                  <h3>
                    {messages.opportunity_details.contractual_information}
                  </h3>
                  <div className="right_contra">
                    <div
                      className="AddBox"
                      onClick={() => setContractuelleModal(true)}
                    >
                      <p className="addName">
                        {
                          messages.opportunity_details
                            .add_contractual_information
                        }
                      </p>
                      <p className="plus_button">+</p>
                    </div>

                    {!opportunity?.informationContractuelle && (
                      <>
                        <p>
                          {
                            messages.opportunity_details.contractual
                              .prestataire_actual
                          }
                          <b>{messages.opportunity_details.contractual.any}</b>
                        </p>
                        <p>
                          {
                            messages.opportunity_details.contractual
                              .prestation_actual
                          }
                          <b>{messages.opportunity_details.contractual.any}</b>
                        </p>
                        <p>
                          {messages.opportunity_details.contractual.invest_type}
                          <b>{messages.opportunity_details.contractual.any}</b>
                        </p>
                        <p>
                          {messages.opportunity_details.contractual.date}
                          <b>{messages.opportunity_details.contractual.any}</b>
                        </p>
                      </>
                    )}

                    {opportunity?.informationContractuelle &&
                      JSON.parse(opportunity?.informationContractuelle)[
                        "Prestataire_Actuelle"
                      ] == undefined && (
                        <p>
                          {
                            messages.opportunity_details.contractual
                              .prestataire_actual
                          }
                          <b>{messages.opportunity_details.contractual.any}</b>
                        </p>
                      )}

                    {opportunity?.informationContractuelle &&
                      JSON.parse(opportunity?.informationContractuelle)[
                        "Prestation_Actuelle"
                      ] == undefined && (
                        <p>
                          {
                            messages.opportunity_details.contractual
                              .prestation_actual
                          }
                          <b>{messages.opportunity_details.contractual.any}</b>
                        </p>
                      )}

                    {opportunity?.informationContractuelle &&
                      JSON.parse(opportunity?.informationContractuelle)[
                        "Type_D'investissement"
                      ] == undefined && (
                        <p>
                          {messages.opportunity_details.contractual.invest_type}
                          <b>{messages.opportunity_details.contractual.any}</b>
                        </p>
                      )}

                    {opportunity?.informationContractuelle &&
                      JSON.parse(opportunity?.informationContractuelle)[
                        "Date_previsionelle_fin_de_contrat"
                      ] == undefined && (
                        <p>
                          {messages.opportunity_details.contractual.date}
                          <b>{messages.opportunity_details.contractual.any}</b>
                        </p>
                      )}

                    {opportunity?.informationContractuelle &&
                      Object.keys(
                        JSON.parse(opportunity.informationContractuelle)
                      ).map((key, index) => {
                        return (
                          <p>
                            {capitalizeFirstLetter(key.replaceAll("_", " "))}
                            {" : "}
                            <b>
                              {capitalizeFirstLetter(
                                JSON.parse(
                                  opportunity.informationContractuelle
                                )[key]
                              )}
                            </b>
                          </p>
                        );
                      })}
                  </div>
                </div>
                <div className="details-right">
                  <div className="details-chat">
                    <div className="details-chat-header">
                      <div className="details-chat-header-left">
                        <p className="details-selected">
                          {messages.chat.activity}
                        </p>
                        <p style={{ cursor: "not-allowed" }}>
                          {messages.chat.chat}
                        </p>
                      </div>
                      <div className="details-show-more">
                        <p>{messages.chat.show_more} +</p>
                      </div>
                    </div>
                    <div className="details-chat-messages" id="myDiv">
                      {thisMonth.length > 0 && (
                        <>
                          <h4>{messages.chat.this_month}</h4>
                        </>
                      )}
                      {thisMonth?.reverse().map((message, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              className="details-mymessage-header"
                            >
                              <p className="details-messages-status">
                                {message.statusId.name}
                              </p>
                              <p className="details-messages-date">
                                {timestampToDDMMYYYY(message.date)}
                              </p>
                            </div>
                            <p className="details-mymessage-content selectable">
                              <span>@{message.userId.username}</span>{" "}
                              {message.text}
                            </p>
                          </>
                        );
                      })}
                      {lastMonth.length > 0 && (
                        <>
                          <h4>{messages.chat.last_month}</h4>
                        </>
                      )}
                      {lastMonth?.reverse().map((message, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              className="details-mymessage-header"
                            >
                              <p className="details-messages-status">
                                {message.statusId.name}
                              </p>
                              <p className="details-messages-date">
                                {timestampToDDMMYYYY(message.date)}
                              </p>
                            </div>
                            <p className="details-mymessage-content selectable">
                              <span>@{message.userId.username}</span>{" "}
                              {message.text}
                            </p>
                          </>
                        );
                      })}
                      {other.length > 0 && (
                        <>
                          <h4>{messages.chat.other}</h4>
                        </>
                      )}
                      {other?.reverse().map((message, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              className="details-mymessage-header"
                            >
                              <p className="details-messages-status">
                                {message.statusId.name}
                              </p>
                              <p className="details-messages-date">
                                {timestampToDDMMYYYY(message.date)}
                              </p>
                            </div>
                            <p className="details-mymessage-content selectable">
                              <span>@{message.userId.username}</span>{" "}
                              {message.text}
                            </p>
                          </>
                        );
                      })}
                    </div>
                    <div className="details-chat-send">
                      <div className="details-chat-send-header">
                        <img
                          src={Mail}
                          alt="logo"
                          style={{ width: "13px", height: "8.5px" }}
                          className="details-chat-status"
                          id={allStatus.mail}
                          onClick={(e) => selectStatusHandler(e)}
                        />
                        <img
                          src={GreenPhone}
                          alt="logo"
                          style={{ width: "14px", height: "14px" }}
                          className="details-chat-status"
                          id={allStatus.AA}
                          onClick={(e) => selectStatusHandler(e)}
                        />
                        <img
                          src={RedPhone}
                          alt="logo"
                          style={{ width: "18px", height: "7px" }}
                          className="details-chat-status"
                          id={allStatus.ANA}
                          onClick={(e) => selectStatusHandler(e)}
                        />
                        <img
                          src={Car}
                          alt="logo"
                          style={{ width: "15px", height: "10.3px" }}
                          className="details-chat-status"
                          id={allStatus.PP}
                          onClick={(e) => selectStatusHandler(e)}
                        />
                      </div>
                      <textarea
                        id="comment"
                        className="details-chat-text"
                        placeholder="Commentaire : "
                      />
                    </div>
                    <div className="details-sendMessage">
                      <img
                        src={SendMessage}
                        alt="logo"
                        onClick={() => sendMessageHandler()}
                      />
                    </div>
                  </div>
                  <div className="details-chat-footer">
                    <div className="details-chat-button">
                      <p className="details-create">
                        Crée le :{" "}
                        {timestampToDDMMYYYY(opportunity.creationDate)}
                      </p>
                      <div
                        className="details-open-map border-fullscreen border-main-button"
                        onClick={() => openMap()}
                      >
                        <p>{messages.opportunity_details.open_map}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!isLastPosition() && (
              <span className="arrowSVGRight" onClick={() => moveRight()}>
                <Arrowsvg />
              </span>
            )}
          </div>
        )}
        {!alreadyLoaded ||
          (!opportunity && (
            <div className="loadingScreen">
              <img src={loader} alt="logo" />
            </div>
          ))}
      </div>
      <ToastContainer autoClose={8000} />
      <LooseModal setShowModal={looseModalFunction} setLoose={looseHandler} />
      <WaitModal
        setShowModal={waitModalFunction}
        setWaiting={waitHandler}
        messages={opportunity?.message}
      />
      <TodoModal
        setShowModal={todoModalFunction}
        setWaiting={todoHandler}
        messages={opportunity?.message}
      />
      <ContractuelleModal
        setShowModal={contractuelleModalFunction}
        setContractuelle={contractuelleHandler}
        value={opportunity?.informationContractuelle}
      />
      <EditProspectModal
        setShowModal={editProspectModalFunction}
        setContractuelle={editProspectHandler}
        value={opportunity?.prospectId?.value}
        excel={opportunity?.excel}
        defaultInformation={opportunity?.prospectId}
      />
      <MessageModal
        setShowModal={sendMessageModalFunction}
        setMessages={messageHandler}
        messages={opportunity?.message}
      />
    </div>
  );
};

export default OpportunityDetails;
