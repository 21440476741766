import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../../config.json";
import Papa from "papaparse";
import history from "../../../../history";
import "../modal.css";
import "./ImportExcelModal.css";

import Point from "../../../../assets/images/navbar/point.png";
import ImportExcel from "../../../../assets/images/icon/import.png";
import Plus from "../../../../assets/images/icon/plus.png";
import ExcelFile from "../../../../assets/images/icon/excel.png";
import ImportButtonImage from "../../../../assets/images/icon/importButton.png";

const excelModal = ({ setShowModal, toast }) => {
  const [isActive, setIsActive] = React.useState(false);
  const [jsonResult, setJsonResult] = useState(null);
  const [option, setOption] = useState([]);
  const [validation, setValidation] = useState(false);
  const [data, setData] = useState(null);
  const [selectorDatas, setSelectorDatas] = useState({});
  const [title, setTitle] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);

  const showModal = (state) => {
    if (true) {
      setJsonResult(null);
      setOption([]);
      setData(null);
    }

    setIsActive(state);
  };

  const handleImport = async () => {
    const myAllData = [
      "selector-Prospect",
      "selector-Enseigne",
      "selector-CodePostale",
      "selector-Ville",
      "selector-AdresseComplete",
      "selector-Site",
      "selector-Telephone",
      "selector-E-Mail",
      "selector-Siret",
      "selector-Libelleactivite",
      "selector-trancheSalarier",
    ];
    const finalData = {};
    myAllData.forEach((item) => {
      if (!Object.values(selectorDatas).includes(item)) {
        finalData[item] = "Aucun";
      } else {
        finalData[item] = Object.keys(selectorDatas).find(
          (key) => selectorDatas[key] === item
        );
      }
    });

    const token = await localStorage.getItem("token");
    const organisationId = await localStorage.getItem("CurrentOrganisation");
    const selector = {};
    const allSelects = document.getElementsByClassName("myModal-custom-select");
    Array.prototype.forEach.call(allSelects, (select) => {
      selector[select.id] = select.value;
    });
    axios
      .post(config.server_ip + "/api/prospect/load", {
        token: token,
        value: finalData,
        exel: jsonResult,
        name: title,
        organisationId: organisationId,
        isPrivate: isPrivate,
      })
      .then((response) => {
        if (window.location.pathname === "/dashboard/prospect/list")
          window.location.reload();
        else history.push("/dashboard/prospect/list");
      })
      .catch((error) => {});
  };

  const closeModalWithoutCall = () => {
    const menu = document.getElementById("myModal");
    menu.classList.add("modal-fadeOut");
    setTimeout(() => {
      setIsActive(false);
      setTimeout(() => {
        menu.classList.remove("modal-fadeOut");
      }, 100);
    }, 200);
  };

  const closeModal = (needRefresh) => {
    const menu = document.getElementById("myModal");
    menu.classList.add("modal-fadeOut");
    setTimeout(() => {
      setIsActive(false);
      if (needRefresh) window.location.reload();
      setTimeout(() => {
        menu.classList.remove("modal-fadeOut");
      }, 100);
    }, 200);
  };

  useEffect(() => {
    if (setShowModal.current === null) {
      setShowModal.current = showModal;
    }
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          if (result.data.length === 0)
            return toast.error("Fichier vide", {
              position: "bottom-right",
              autoClose: 3000,
              theme: "dark",
            });
          setJsonResult(result.data);
          setOption(Object.keys(result.data[0]));
        },
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
      });
    }
  };

  const verifData = () => {
    const title = document.getElementById("title").value;
    const access = document.getElementById("access").value;

    if (title === "" || access === "")
      return toast.error("Veuillez remplir tous les champs", {
        position: "bottom-right",
        autoClose: 3000,
        theme: "dark",
      });

    if (title.length < 5)
      return toast.error("Le titre doit contenir au moins 5 caractères", {
        position: "bottom-right",
        autoClose: 3000,
        theme: "dark",
      });

    setData({ title, access });
  };

  return (
    <div>
      {isActive && (
        <div id="myModal" className="myModal">
          {!validation && !data && (
            <div className="myModal-content border-gradient border-l">
              <div className="myModal-close-container">
                <img
                  className="myModal-close"
                  src={Point}
                  alt="close"
                  onClick={() => closeModal()}
                />
              </div>
              <img className="myModal-img" src={ImportExcel} alt="checked" />
              <p className="myModal-title">Excel & CSV</p>
              <p className="myModal-description">Importer vos fichiers CSV</p>
              {jsonResult && (
                <label htmlFor="file" className="custom-file-label excelFile">
                  <img src={ExcelFile} alt="Sélectionnez un fichier" />
                </label>
              )}
              {!jsonResult && (
                <label htmlFor="file" className="custom-file-label">
                  <img src={Plus} alt="Sélectionnez un fichier" />
                </label>
              )}

              <input
                type="file"
                id="file"
                name="file"
                accept=".csv"
                className="custom-file-input"
                onChange={handleFileChange}
              />
              <p className="myModal-description" style={{ marginTop: "20px" }}>
                <b>Importer un fichier</b>
              </p>

              {jsonResult && (
                <div className="custom-import-button">
                  <div
                    className="import-button-modal"
                    onClick={() => setValidation(true)}
                  >
                    <img src={ImportButtonImage} alt="import" />
                    <p>Importer</p>
                  </div>
                </div>
              )}
            </div>
          )}
          {validation && !data && (
            <div className="myModal-content border-gradient border-l">
              <div className="myModal-close-container-2">
                <img
                  className="myModal-close"
                  src={Point}
                  alt="close"
                  onClick={() => closeModal()}
                />
              </div>
              <img className="myModal-img" src={ImportExcel} alt="checked" />
              <p className="myModal-title">Validation</p>
              <p className="myModal-description">
                Validation importer fichier <b>CSV</b>
              </p>

              <p className="custom-title">Base de donnée du fichier</p>

              <div className="custom-input-content">
                <label className="">Titre :</label>
                <input
                  type="text"
                  id="title"
                  placeholder="Titre"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="custom-input-content">
                <label className="">Accessible par : </label>
                <select
                  id="access"
                  name="access"
                  className="custom-select"
                  onChange={(e) =>
                    setIsPrivate(e.target.value === "yes" ? true : false)
                  }
                >
                  <option value="no">Tout le monde</option>
                  <option value="yes">Seulement moi</option>
                </select>
              </div>
              <div
                className="custom-import-button-2"
                onClick={() => verifData()}
              >
                <div className="save-button border-fullscreen border-gradient">
                  Suivant
                </div>
              </div>
            </div>
          )}
          {validation && data && (
            <div className="myModal-content border-gradient border-l custom-modal">
              <p className="myModal-title">Validation</p>
              <p className="myModal-description">
                Validation Colonne du fichier de prospection
              </p>
              <div className="myModal-separator">
                <table
                  className="myModal-table"
                  id="myTable"
                  style={{
                    height: "469px",
                    overflow: "auto",
                    display: "block",
                  }}
                >
                  <thead>
                    <tr>
                      <th className="data-table">
                        Première ligne de votre tableau
                      </th>
                      <th className="prospect-table">Valeur associé</th>
                      <th className="prospect-table">Prospect 1</th>
                      <th className="prospect-table">Prospect 2</th>
                    </tr>
                  </thead>

                  <tbody style={{ display: "block" }}>
                    {option.map((item, index) => (
                      <tr key={index}>
                        <td className="data-table">{item}</td>
                        <td className="prospect-table">
                          <select
                            class="myModal-custom-select"
                            onChange={(e) => {
                              setSelectorDatas({
                                ...selectorDatas,
                                [item]: e.target.value,
                              });
                            }}
                          >
                            <option value="null">Conserver le titre</option>
                            <option value="null">Supprimer la ligne</option>
                            <option disabled>───────────────────</option>
                            <option disabled>
                              Associer aux valeurs suivantes
                            </option>
                            <option disabled>───────────────────</option>
                            <option value="selector-Prospect">
                              Raison Social
                            </option>
                            <option value="selector-Enseigne">Enseigne</option>
                            <option value="selector-CodePostale">
                              Code Postal
                            </option>
                            <option value="selector-Ville">Ville</option>
                            <option value="selector-AdresseComplete">
                              Adresse
                            </option>
                            <option value="selector-Site">Site</option>
                            <option value="selector-Telephone">
                              Téléphone
                            </option>
                            <option value="selector-E-Mail">Email</option>
                            <option value="selector-Siret">Siret</option>
                            <option value="selector-Libelleactivite">
                              Libelle Activité
                            </option>
                            <option value="selector-trancheSalarier">
                              Tranche effectif établissement
                            </option>
                          </select>
                        </td>
                        <td className="prospect-table">
                          {jsonResult[0][item]}
                        </td>
                        <td className="prospect-table">
                          {jsonResult[1][item]}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="myModal-button">
                  <div
                    className="save-button border-fullscreen border-gradient"
                    onClick={() => handleImport()}
                  >
                    Valider
                  </div>
                  <div
                    style={{ marginRight: "10px" }}
                    className="cancel-button"
                    onClick={() => closeModalWithoutCall()}
                  >
                    Annuler
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default excelModal;
