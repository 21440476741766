import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from "./components/auth/home/Home";
import Login from "./components/auth/login/Login";
import Forgot from "./components/auth/forgot/Forgot";
import ResetPass from "./components/auth/resetPass/ResetPass";
import Register from "./components/auth/register/Register";
import DashboardHome from "./components/dashboard/home/Home";
import prospectHome from "./components/dashboard/prospect/home/Home";
import Join from "./components/invitation/join/Join";
import OpportunityList from "./components/dashboard/prospect/list/OpportunityList";
import ProspectInformation from "./components/dashboard/prospect/show/ProspectInformation";
import OpportunityDetails from "./components/dashboard/opportunity/details/OpportunityDetails";
import RealOpportunityList from "./components/dashboard/opportunity/list/OpportunityList";

import Feedback from "./components/feedback/Feedback";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route
            path="/dashboard/prospect/show/:id"
            component={ProspectInformation}
          />
          <Route path="/dashboard/prospect/list" component={OpportunityList} />
          <Route path="/dashboard/prospect" component={prospectHome} />
          <Route
            path="/dashboard/opportunity/list/:filter"
            component={RealOpportunityList}
          />
          <Route
            path="/dashboard/opportunity/:id"
            component={OpportunityDetails}
          />
          <Route path="/dashboard" component={DashboardHome} />
          <Route path="/invitation/join/:id" component={Join} />
          <Route path="/reset-password" component={ResetPass} />
          <Route path="/forgot" component={Forgot} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/" component={Home} />
        </Switch>
      </Router>
      <Feedback />
    </>
  );
}

export default App;
