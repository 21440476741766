import React, { useEffect } from "react";
import valid from "../../../../assets/images/icon/modal_valid.png";
import "../modal.css";

const looseModal = ({ setShowModal, setLoose }) => {
  const [isActive, setIsActive] = React.useState(false);
  const [validationActive, setValidationActive] = React.useState(false);

  const showModal = (state) => {
    setIsActive(state);
  };

  const setValidation = () => {
    setValidationActive(true);
    setLoose();
    setTimeout(() => {
      closeModal(true);
    }, 2000);
  };

  const closeModal = (needRefresh) => {
    const menu = document.getElementById("myModal");
    menu.classList.add("modal-fadeOut");
    setTimeout(() => {
      setIsActive(false);
      if (needRefresh) window.location.reload();
      setTimeout(() => {
        setValidationActive(false);
        menu.classList.remove("modal-fadeOut");
      }, 100);
    }, 200);
  };

  useEffect(() => {
    if (setShowModal.current === null) {
      setShowModal.current = showModal;
    }
  }, [setShowModal, showModal]);
  return (
    <div>
      {isActive && (
        <div id="myModal" className="myModal">
          {!validationActive && (
            <div className="myModal-content border-gradient border-l">
              <p className="myModal-title">Enregistrer</p>
              <p className="myModal-description">
                Valider et enregistrer vos Informations ?
              </p>
              <div className="myModal-button">
                <div
                  className="save-button border-fullscreen border-gradient"
                  onClick={() => setValidation()}
                >
                  Enregistrer
                </div>
                <div className="cancel-button" onClick={() => closeModal()}>
                  Annuler
                </div>
              </div>
            </div>
          )}
          {validationActive && (
            <div className="myModal-content border-gradient border-l">
              <img className="myModal-img" src={valid} alt="checked" />
              <p className="myModal-title">Validé</p>
              <p className="myModal-description">Information Enregistrer</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default looseModal;
