import React, { useEffect, useState } from "react";
import "./Login.css";
import Clock from "../../widgets/clock/Clock";
import history from "../../../history";
import axios from "axios";
import config from "../../../config.json";
import { ToastContainer, toast } from "react-toastify";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      history.push("/dashboard");
    }
  });

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(config.server_ip + "/api/login", { email, password })
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        history.push("/dashboard");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: "bottom-right",
          theme: "dark",
        });
      });
  };

  return (
    <div
      className="prospect_background_component loginComponent"
      onKeyDown={(e) => handleEnterPress(e)}
      tabIndex={0}
    >
      <div className="header">
        <div className="header_right">
          <Clock unactive={true} />
        </div>
      </div>
      <div className="login">
        <div className="mainLogin border-gradient border-xl">
          <h3>Connexion</h3>
          <input
            className="login_input"
            type="text"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="login_input"
            type="password"
            placeholder="Mot de passe"
            onChange={(e) => setPassword(e.target.value)}
          />
          <p className="forgotPass" onClick={() => history.push("/forgot")}>
            Mot de passe oublier ?
          </p>
          <div className="buttonContain">
            <button
              className="register_button"
              onClick={() => history.push("/register")}
            >
              S'inscrire
            </button>
            <button className="login_button" onClick={(e) => handleSubmit(e)}>
              SE CONNECTER
            </button>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={8000} />
    </div>
  );
};

export default Login;
