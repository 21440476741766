import React, { useEffect, useState } from "react";
import "../login/Login.css";
import Clock from "../../widgets/clock/Clock";
import history from "../../../history";
import axios from "axios";
import config from "../../../config.json";
import { ToastContainer, toast } from "react-toastify";

const Forgot = () => {
  const [email, setEmail] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      history.push("/dashboard");
    }
  });

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(config.server_ip + "/api/forgot", { email })
      .then((response) => {
        toast.success(response.data.message, {
          position: "bottom-right",
          theme: "dark",
        });
        setTimeout(() => {
          history.push("/login");
        }, 2000);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: "bottom-right",
          theme: "dark",
        });
      });
  };

  return (
    <div
      className="prospect_background_component loginComponent"
      onKeyDown={(e) => handleEnterPress(e)}
      tabIndex={0}
    >
      <div className="header">
        <div className="header_right">
          <Clock unactive={true} />
        </div>
      </div>
      <div className="login">
        <div className="mainLogin border-gradient border-xl">
          <h3>Mot de passe perdu</h3>
          <input
            className="login_input"
            type="text"
            placeholder="Adresse mail"
            onChange={(e) => setEmail(e.target.value)}
          />
          <div
            className="buttonContain"
            style={{ justifyContent: "flex-end", marginTop: "10px" }}
          >
            <button className="login_button" onClick={(e) => handleSubmit(e)}>
              Envoyer
            </button>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={8000} />
    </div>
  );
};

export default Forgot;
