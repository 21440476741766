import React, { useEffect } from "react";
import "../modal.css";
import "./newOpportunity.css";
import messages from "../../../../message.json";

import valid from "../../../../assets/images/icon/modal_valid.png";
import plus from "../../../../assets/images/icon/plus.png";
import OpportunityIcon from "../../../../assets/images/icon/opportunity_logo.png";
import { all } from "axios";

const newOpportunity = ({ setShowModal, toast, setNewOpportunity }) => {
  const [isActive, setIsActive] = React.useState(false);
  const [validationActive, setValidationActive] = React.useState(false);
  const [menuNumber, setMenuNumber] = React.useState(1);

  const showModal = (state) => {
    setIsActive(state);
  };

  const setValidation = () => {
    const allData = checkAllData();

    if (!allData.title) return;

    setValidationActive(true);
    setTimeout(() => {
      setNewOpportunity(allData);
      closeModal(false);
    }, 2000);
  };

  const checkAllData = () => {
    const title = document.getElementById("import-title").value;
    const enseigne = document.getElementById("import-enseigne").value;
    const codePostale = document.getElementById("import-codePostale").value;
    const ville = document.getElementById("import-ville").value;
    const adresse = document.getElementById("import-adresse").value;
    const telephone = document.getElementById("import-telephone").value;
    const email = document.getElementById("import-email").value;
    const siret = document.getElementById("import-siret").value;
    const libelle = document.getElementById("import-libelle").value;
    const metier = document.getElementById("import-metier").value;
    const tranche = document.getElementById("import-tranche").value;
    const state = document.getElementById("import-state").value;
    let dateStatus = document.getElementById("import-date").value;
    const timestamp = new Date(dateStatus).getTime();

    const data = {
      title,
      enseigne,
      codePostale,
      ville,
      adresse,
      telephone,
      email,
      siret,
      libelle,
      metier,
      tranche,
      contact: [],
      state,
      dateStatus: timestamp,
    };

    for (let i = 1; i <= menuNumber; i++) {
      const surname = document.getElementById("import-surname:" + i).value;
      const name = document.getElementById("import-name:" + i).value;
      const fonction = document.getElementById("import-function:" + i).value;
      const phone = document.getElementById("import-phone:" + i).value;
      const mail = document.getElementById("import-email:" + i).value;
      const civilite = document.getElementById("import-civilite:" + i).value;

      data.contact.push({
        surname,
        name,
        fonction,
        phone,
        mail,
        civilite,
      });
    }

    if (!title) {
      return toast.error(
        "Veuillez renseigner le raison social de l'opportunité",
        {
          position: "bottom-right",
          theme: "dark",
        }
      );
    }

    if (!codePostale || !ville || !adresse) {
      return toast.error("Veuillez renseigner l'adresse complète", {
        position: "bottom-right",
        theme: "dark",
      });
    }

    if (!telephone) {
      return toast.error("Veuillez renseigner le téléphone", {
        position: "bottom-right",
        theme: "dark",
      });
    }

    if (!dateStatus) {
      return toast.error("Veuillez renseigner la date de status", {
        position: "bottom-right",
        theme: "dark",
      });
    }

    return data;
  };

  const closeModal = (needRefresh) => {
    const menu = document.getElementById("import-myModal");
    menu.classList.add("modal-fadeOut");
    setTimeout(() => {
      setIsActive(false);
      if (needRefresh) window.location.reload();
      setTimeout(() => {
        setValidationActive(false);
        menu.classList.remove("modal-fadeOut");
      }, 100);
    }, 200);
  };

  useEffect(() => {
    if (setShowModal.current === null) {
      setShowModal.current = showModal;
    }
  }, [setShowModal, showModal, setMenuNumber, menuNumber]);
  return (
    <div>
      {isActive && (
        <div id="import-myModal" className="myModal">
          {!validationActive && (
            <div className="myModal-content border-gradient border-l">
              <img
                className="myModal-img"
                src={OpportunityIcon}
                alt="checked"
              />
              <p className="myModal-title">
                {messages.modal.import_opportunity.title}
              </p>
              <p className="myModal-description">
                {messages.modal.import_opportunity.description}
              </p>

              <br />

              <div className="custom-height">
                <p className="custom-contact-info">
                  {messages.modal.import_opportunity.sub_title}
                </p>

                <div className="custom-input-content">
                  <label className="">Titre de l'opportunité :</label>
                  <input
                    type="text"
                    id="import-title"
                    placeholder="raison sociale"
                  />
                </div>

                <br />

                <div className="custom-input-content">
                  <label className="">Enseigne :</label>
                  <input
                    type="text"
                    id="import-enseigne"
                    placeholder="Enseigne"
                  />
                </div>

                <br />

                <div className="custom-input-content">
                  <label className="">Rencontrer le :</label>
                  <input type="date" id="import-rencontre" />
                </div>

                <br />

                <div className="custom-input-content">
                  <label className="">Adresse Principale :</label>
                  <input
                    type="text"
                    id="import-adresse"
                    placeholder="Adresse Principale"
                  />
                </div>

                <div className="custom-input-content">
                  <label className="">Code Postale :</label>
                  <input
                    type="text"
                    id="import-codePostale"
                    placeholder="Code Postale"
                  />
                </div>

                <div className="custom-input-content">
                  <label className="">Ville :</label>
                  <input type="text" id="import-ville" placeholder="Ville" />
                </div>

                <br />

                <div className="custom-input-content">
                  <label className="">Téléphone :</label>
                  <input
                    type="text"
                    id="import-telephone"
                    placeholder="Téléphone"
                  />
                </div>

                <div className="custom-input-content">
                  <label className="">E-mail générique :</label>
                  <input type="text" id="import-email" placeholder="E-mail" />
                </div>

                <br />

                <div className="custom-input-content">
                  <label className="">Siret :</label>
                  <input type="text" id="import-siret" placeholder="Siret" />
                </div>

                <div className="custom-input-content">
                  <label className="">Libellé activité :</label>
                  <input
                    type="text"
                    id="import-libelle"
                    placeholder="Libellé activité"
                  />
                </div>

                <br />

                <div className="custom-input-content">
                  <label className="">Métier :</label>
                  <input type="text" id="import-metier" placeholder="Métier" />
                </div>

                <div className="custom-input-content">
                  <label className="">Tranche effectif établissement :</label>
                  <input
                    type="text"
                    id="import-tranche"
                    placeholder="Tranche effectif établissement"
                  />
                </div>

                <div className="custom-input-content">
                  <label className="">Nature établissement :</label>
                  <select
                    id="import-state"
                    name="state"
                    className="custom-select"
                  >
                    <option value="Siège social">Siège social</option>
                    <option value="WIN">Établissement secondaire</option>
                  </select>
                </div>

                <br />

                {Array.from(Array(menuNumber)).map((e, i) => (
                  <>
                    <p className="custom-contact-info">
                      {messages.modal.import_opportunity.sub_title_2}
                      {i + 1}
                    </p>

                    <div className="custom-input-content">
                      <label className="">Civilité :</label>
                      <select
                        id={"import-civilite:" + (i + 1)}
                        className="custom-select"
                      >
                        <option value="Mr.">M.</option>
                        <option value="Mme.">Mme.</option>
                      </select>
                    </div>

                    <div className="custom-input-content">
                      <label className="">Nom :</label>
                      <input
                        type="text"
                        id={"import-surname:" + (i + 1)}
                        placeholder="Nom"
                      />
                    </div>

                    <div className="custom-input-content">
                      <label className="">Prenom :</label>
                      <input
                        type="text"
                        id={"import-name:" + (i + 1)}
                        placeholder="Prenom"
                      />
                    </div>

                    <div className="custom-input-content">
                      <label className="">Fonction :</label>
                      <input
                        type="text"
                        id={"import-function:" + (i + 1)}
                        placeholder="Fonction"
                      />
                    </div>

                    <div className="custom-input-content">
                      <label className="">Téléphone - Standard :</label>
                      <input
                        type="text"
                        id={"import-phone:" + (i + 1)}
                        placeholder="Téléphone"
                      />
                    </div>

                    <div className="custom-input-content">
                      <label className="">Email :</label>
                      <input
                        type="text"
                        id={"import-email:" + (i + 1)}
                        placeholder="Email"
                      />
                    </div>
                  </>
                ))}

                <div
                  className="custom-add-message"
                  onClick={() => setMenuNumber(menuNumber + 1)}
                >
                  <img className="plus-icon" src={plus} alt="plus" />
                  <p>Ajouter un contact</p>
                </div>

                <p className="custom-contact-info">
                  {messages.modal.import_opportunity.sub_title_3}
                </p>

                <div className="custom-input-content">
                  <label className="">Assigner à :</label>
                  <label id="import-assignTo">Toi (Ne peut être changé)</label>
                </div>

                <br />

                <div className="custom-input-content">
                  <label className="">État : </label>
                  <select
                    id="import-state"
                    name="state"
                    className="custom-select"
                  >
                    <option value="TODO">En attente</option>
                    <option value="WIN">Gagné</option>
                  </select>
                </div>

                <br />

                <div className="custom-input-content">
                  <label className="">Date status :</label>
                  <input type="datetime-local" id="import-date" />
                </div>
              </div>

              <div className="myModal-button">
                <div
                  className="save-button border-fullscreen border-gradient"
                  onClick={() => setValidation()}
                >
                  Enregistrer
                </div>
                <div className="cancel-button" onClick={() => closeModal()}>
                  Annuler
                </div>
              </div>
            </div>
          )}
          {validationActive && (
            <div className="myModal-content border-gradient border-l">
              <img className="myModal-img" src={valid} alt="checked" />
              <p className="myModal-title">Validé</p>
              <p className="myModal-description">Information Enregistrer</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default newOpportunity;
