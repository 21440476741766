import React, { useEffect, useRef } from "react";
import axios from "axios";
import config from "../../../../config.json";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ProgressBar from "@ramonak/react-progress-bar";
import history from "../../../../history";

import "./OpportunityList.css";
import "react-toastify/dist/ReactToastify.css";

import defaultProfil from "../../../../assets/images/icon/default_icon.png";
import loader from "../../../../assets/images/icon/loader.png";
import arrow from "../../../../assets/images/icon/arrow.png";
import trash from "../../../../assets/images/icon/trash.png";
import loupe from "../../../../assets/images/icon/loupe.png";

import Navbar from "../../../navbar/navbar";
import Clock from "../../../widgets/clock/Clock";
import ExcelModal from "../../../widgets/modal/importExcelModal/ImportExcelModal";

const OpportunityDetails = () => {
  const { id } = useParams();
  const excelModalFunction = useRef(null);
  const [alreadyLoaded, setAlreadyLoaded] = React.useState(false);
  const [organisation, setOrganisation] = React.useState([]);
  const [currentOrganisation, setCurrentOrganisation] = React.useState("");
  const [prospects, setProspects] = React.useState(null);
  const [saveProspect, setSaveProspect] = React.useState(null);

  useEffect(() => {
    const main = async () => {
      if (alreadyLoaded) return;
      const token = await localStorage.getItem("token");

      if (organisation.length === 0)
        axios
          .post(config.server_ip + "/api/organisation/get", { token })
          .then(async (response) => {
            if (response.data.organisations.length == 0)
              history.push("/dashboard");
            setOrganisation(response.data.organisations);
            if (
              (await localStorage.getItem("CurrentOrganisation")) == undefined
            ) {
              localStorage.setItem(
                "CurrentOrganisation",
                response.data.organisations[0]._id
              );
              setCurrentOrganisation(response.data.organisations[0]._id);
            } else {
              setCurrentOrganisation(
                await localStorage.getItem("CurrentOrganisation")
              );
            }
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: "bottom-right",
              theme: "dark",
            });
          });

      if (!prospects)
        axios
          .post(config.server_ip + "/api/prospect/get/all", {
            token,
            organisationId: await localStorage.getItem("CurrentOrganisation"),
          })
          .then((response) => {
            setProspects(response.data.value);
            setSaveProspect(response.data.value);
          })
          .catch((error) => {});

      setAlreadyLoaded(true);
    };

    main();
  }, [currentOrganisation]);

  const redirect = (redirect) => {
    history.push(redirect);
  };

  const deleteExcel = (id, name) => {
    axios
      .post(config.server_ip + "/api/prospect/delete", {
        token: localStorage.getItem("token"),
        excelId: id,
        organisationId: currentOrganisation,
      })
      .then((response) => {
        toast.success(`${name} a correctement été supprimé !`, {
          position: "bottom-right",
          theme: "dark",
        });
        window.location.reload();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: "bottom-right",
          theme: "dark",
        });
      });
  };

  const timestampToDDMMYYYY = (timestamp) => {
    const date = new Date(parseInt(timestamp));

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const paddedDay = day < 10 ? "0" + day : day;
    const paddedMonth = month < 10 ? "0" + month : month;

    return `${paddedDay}/${paddedMonth}/${year}`;
  };

  const openMenu = (menuId) => {
    const myMenu = document.getElementById(menuId);
    if (!myMenu) return;
    if (myMenu.classList.contains("menuOff")) {
      myMenu.classList.remove("menuOff");
      myMenu.classList.add("border-gradient");
      myMenu.classList.add("border-m");
    } else {
      myMenu.classList.add("menuOff");
      myMenu.classList.remove("border-gradient");
      myMenu.classList.remove("border-m");
    }
  };

  const setExcelModal = (state) => {
    if (excelModalFunction.current) {
      excelModalFunction.current(state);
    }
  };

  const searchHandler = (e) => {
    const search = e.target.value;
    if (search === "") {
      setProspects(saveProspect);
      return;
    }

    const filteredProspects = saveProspect.filter((prospect) =>
      prospect.name.toLowerCase().includes(search.toLowerCase())
    );

    setProspects(filteredProspects);
  };

  return (
    <div className="prospect_background_component">
      <Navbar
        currentOrganisation={organisation.filter(
          (o) => o["_id"] === currentOrganisation
        )}
        selected="Home"
        allOrganisation={organisation}
        setOrga={(organisationId) => {
          setAlreadyLoaded(false);
          setProspects(null);
          setCurrentOrganisation(organisationId);
        }}
      />
      <div className="main">
        <div className="main_header">
          <Clock />
        </div>
        {alreadyLoaded && prospects && (
          <div className="myMain opportunityList border-gradient border-fullscreen">
            <div className="excelListHeader">
              <h2>Fichier de prospection</h2>
              <div className="excelButtonContainer">
                <div>
                  <div
                    className="importExcelButton border-gradient border-s"
                    onClick={() => openMenu("importExcelButton")}
                  >
                    <p>Ajout d’un fichier de prospection</p>
                    <img src={arrow} alt="logo" />
                  </div>
                  <div
                    id="importExcelButton"
                    className="importExcelButtonOpen menuOff"
                  >
                    <div className="openMenuSpace"></div>
                    <p onClick={() => setExcelModal(true)}>
                      Importer un fichier Excel
                    </p>
                    <p>Créer une liste manuellement</p>
                  </div>
                </div>
                <div className="excelSearch">
                  <div className="excelSearch-image border-gradient border-fullscreen">
                    <img src={loupe} alt="logo" />
                  </div>
                  <input
                    type="text"
                    placeholder="Rechercher"
                    onChange={(e) => searchHandler(e)}
                  />
                </div>
              </div>
            </div>
            <div className="myTableGradiant">
              <div className="myTable">
                <table>
                  <thead>
                    <tr>
                      <th scope="col" className="tableUser">
                        Utilisateur
                      </th>
                      <th scope="col" className="tableTitre">
                        Titre
                      </th>
                      <th scope="col" className="tableQualifier">
                        A qualifier
                      </th>
                      <th scope="col" className="tableActive">
                        Opportunités actives
                      </th>
                      <th scope="col" className="tableSuggest">
                        Suggestions
                      </th>
                      <th scope="col" className="tableCreation">
                        Date de création
                      </th>
                      <th scope="col" className="tableMaj">
                        Date de mise a jour
                      </th>
                      <th scope="col" className="tableTag">
                        Tag
                      </th>
                      <th scope="col" className="tableAction"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {prospects?.map((prospect) => (
                      <tr key={prospect._id}>
                        <td
                          className="needHover tableUser"
                          onClick={() =>
                            redirect("/dashboard/prospect/show/" + prospect._id)
                          }
                        >
                          <img
                            src={defaultProfil}
                            alt="icon"
                            className="userIcon"
                          />
                        </td>
                        <td
                          className="needHover tableTitre"
                          onClick={() =>
                            redirect("/dashboard/prospect/show/" + prospect._id)
                          }
                        >
                          {prospect.name}
                        </td>
                        <td
                          className="needHover tableQualifier"
                          onClick={() =>
                            redirect("/dashboard/prospect/show/" + prospect._id)
                          }
                        >
                          {prospect.countSelected + "/" + prospect.count}
                        </td>
                        <td
                          className="needHover tableActive"
                          onClick={() =>
                            redirect("/dashboard/prospect/show/" + prospect._id)
                          }
                        >
                          {prospect.countSelected}
                        </td>
                        <td
                          className="needHover tableSuggest"
                          onClick={() =>
                            redirect("/dashboard/prospect/show/" + prospect._id)
                          }
                        >
                          {(
                            (prospect.countSelected / prospect.count) *
                            100
                          ).toFixed(2) + "%"}
                          <ProgressBar
                            completed={(
                              (prospect.countSelected / prospect.count) *
                              100
                            ).toFixed(2)}
                            width="140px"
                            baseBgColor="#F7F7F7"
                            bgColor="#3AE493"
                            customLabel=" "
                            height="8px"
                          />
                        </td>
                        <td
                          className="needHover tableCreation"
                          onClick={() =>
                            redirect("/dashboard/prospect/show/" + prospect._id)
                          }
                        >
                          {timestampToDDMMYYYY(prospect.date)}
                        </td>
                        <td
                          className="needHover tableMaj"
                          onClick={() =>
                            redirect("/dashboard/prospect/show/" + prospect._id)
                          }
                        >
                          {timestampToDDMMYYYY(prospect.lastModificationDate)}
                        </td>
                        <td className="tableTag">Tag</td>
                        <td className="tableAction">
                          <img
                            className="trashIcon"
                            src={trash}
                            onClick={() => {
                              deleteExcel(prospect._id, prospect.name);
                            }}
                            alt="supprimer"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {!alreadyLoaded ||
          (!prospects && (
            <div className="loadingScreen">
              <img src={loader} alt="logo" />
            </div>
          ))}
      </div>
      <ToastContainer autoClose={8000} />
      <ExcelModal setShowModal={excelModalFunction} toast={toast} />
    </div>
  );
};

export default OpportunityDetails;
