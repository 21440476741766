import React, { useEffect } from "react";
import "./Home.css";
import "./Main.css";
import axios from "axios";
import history from "../../../../history";
import config from "../../../../config.json";
import { ToastContainer, toast } from "react-toastify";

import loader from "../../../../assets/images/icon/loader.png";

import Navbar from "../../../navbar/navbar";
import Clock from "../../../widgets/clock/Clock";
import Fiche from "../fiche/Fiche";

import "./Organisation.css";

const Home = () => {
  const [organisation, setOrganisation] = React.useState([]);
  const [currentOrganisation, setCurrentOrganisation] = React.useState("");
  const [TODO, setTODO] = React.useState([]);
  const [WAITING, setWAITING] = React.useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const token = await localStorage.getItem("token");

      if (organisation.length === 0)
        axios
          .post(config.server_ip + "/api/organisation/get", { token })
          .then(async (response) => {
            if (response.data.organisations.length == 0)
              history.push("/dashboard");
            setOrganisation(response.data.organisations);
            if (
              (await localStorage.getItem("CurrentOrganisation")) == undefined
            ) {
              localStorage.setItem(
                "CurrentOrganisation",
                response.data.organisations[0]._id
              );
              setCurrentOrganisation(response.data.organisations[0]._id);
            } else {
              setCurrentOrganisation(
                await localStorage.getItem("CurrentOrganisation")
              );
            }
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: "bottom-right",
              theme: "dark",
            });
          });

      if (currentOrganisation !== "")
        axios
          .post(config.server_ip + "/api/opportunity/getwithfilter", {
            token,
            filter: { state: "TODO" },
            organisationId: currentOrganisation,
          })
          .then((response) => {
            setTODO(response.data.opportunitys);
            let opportunityResult = [];
            response.data.opportunitys.map((opportunity) => {
              opportunityResult.push(opportunity._id);
            });
            localStorage.setItem("todo", opportunityResult.join(";"));
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: "bottom-right",
              theme: "dark",
            });
          });
      if (currentOrganisation !== "")
        axios
          .post(config.server_ip + "/api/opportunity/getwithfilter", {
            token,
            filter: { state: "WAITING" },
            organisationId: currentOrganisation,
          })
          .then((response) => {
            setWAITING(response.data.opportunitys);
            let opportunityResult = [];
            response.data.opportunitys.map((opportunity) => {
              opportunityResult.push(opportunity._id);
            });
            localStorage.setItem("waiting", opportunityResult.join(";"));
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: "bottom-right",
              theme: "dark",
            });
          });
    };

    fetchData();
  }, [currentOrganisation]);

  return (
    <div className="prospect_background_component">
      <Navbar
        currentOrganisation={organisation.filter(
          (o) => o["_id"] === currentOrganisation
        )}
        selected="Home"
        allOrganisation={organisation}
        setOrga={(organisationId) => {
          setCurrentOrganisation(organisationId);
        }}
      />
      {WAITING != null && (
        <div className="main">
          <div className="main_header">
            <Clock />
          </div>
          <div className="main_body">
            <div className="leftContainer border-gradient border-xl">
              <div className="left_header">
                <div
                  className="left_title border-gradient border-xl cursor-pointer"
                  onClick={() =>
                    history.push("/dashboard/opportunity/list/todo")
                  }
                >
                  <p className="left_text">Opportunité à faire</p>
                  <p className="left_number">{TODO.length}</p>
                </div>
              </div>
              <div className="left_contains">
                {TODO.map((opportunity) => (
                  <>
                    <Fiche
                      key={opportunity._id}
                      opportunity={opportunity}
                      organisation={organisation}
                      type="todo"
                    />
                  </>
                ))}
              </div>
            </div>
            <div className="rightContainer border-gradient border-xl">
              <div className="left_header">
                <div
                  className="right_title cursor-pointer"
                  onClick={() =>
                    history.push("/dashboard/opportunity/list/waiting")
                  }
                >
                  <p className="left_text" style={{ marginRight: "10px" }}>
                    Opportunité en attente
                  </p>
                  <p className="left_number">{WAITING.length}</p>
                </div>
              </div>
              <div className="left_contains">
                {WAITING?.map((opportunity) => (
                  <>
                    <Fiche
                      key={opportunity._id}
                      opportunity={opportunity}
                      organisation={organisation.filter(
                        (o) => o._id === currentOrganisation
                      )}
                      type="waiting"
                    />
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {WAITING == null && (
        <div className="loadingScreen">
          <img src={loader} alt="logo" />
        </div>
      )}

      <ToastContainer autoClose={8000} />
    </div>
  );
};

export default Home;
