import React, { useEffect, useRef } from "react";
import axios from "axios";
import config from "../../../../config.json";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import history from "../../../../history";

import "./OpportunityList.css";
import "react-toastify/dist/ReactToastify.css";

import loader from "../../../../assets/images/icon/loader.png";

import Navbar from "../../../navbar/navbar";
import Clock from "../../../widgets/clock/Clock";
import AllFiche from "../../../widgets/allFiche/allFiche";

const OpportunityList = () => {
  const { filter } = useParams();
  const [alreadyLoaded, setAlreadyLoaded] = React.useState(false);
  const [organisation, setOrganisation] = React.useState([]);
  const [currentOrganisation, setCurrentOrganisation] = React.useState("");
  const [opportunities, setOpportunities] = React.useState(null);
  const [numberOfOpportunities, setNumberOfOpportunities] = React.useState(0);
  const [isFinsh, setIsFinsh] = React.useState(false);
  const [text, setText] = React.useState("");

  useEffect(() => {
    const main = async () => {
      if (
        filter !== "all" &&
        filter !== "todo" &&
        filter !== "waiting" &&
        filter !== "loose" &&
        filter !== "win"
      )
        history.push("/dashboard/opportunity/list/all");
      if (alreadyLoaded) return;
      const token = await localStorage.getItem("token");

      if (organisation.length === 0)
        axios
          .post(config.server_ip + "/api/organisation/get", { token })
          .then(async (response) => {
            if (response.data.organisations.length == 0)
              history.push("/dashboard");
            setOrganisation(response.data.organisations);
            if (
              (await localStorage.getItem("CurrentOrganisation")) == undefined
            ) {
              localStorage.setItem(
                "CurrentOrganisation",
                response.data.organisations[0]._id
              );
              setCurrentOrganisation(response.data.organisations[0]._id);
            } else {
              setCurrentOrganisation(
                await localStorage.getItem("CurrentOrganisation")
              );
            }
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: "bottom-right",
              theme: "dark",
            });
          });

      if (!opportunities) {
        let parameters = {};
        if (filter === "all") {
          setText("toutes");
        }
        if (filter === "todo") {
          parameters = { state: "TODO" };
          setText("a faire");
        }
        if (filter === "waiting") {
          parameters = { state: "WAITING" };
          setText("en attente");
        }
        if (filter === "loose") {
          parameters = { state: "LOOSE" };
          setText("perdues");
        }
        if (filter === "win") {
          parameters = { state: "WIN" };
          setText("gagnées");
        }
        console.log(text);

        axios
          .post(config.server_ip + "/api/opportunity/getwithfilter", {
            token,
            organisationId: await localStorage.getItem("CurrentOrganisation"),
            filter: parameters,
            startId: 0,
            endId: 5,
          })
          .then(async (response) => {
            if (numberOfOpportunities == 0)
              addNumberOfPropertiesWithList(response.data.opportunitys);

            setOpportunities(response.data.opportunitys);
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: "bottom-right",
              theme: "dark",
            });
          });
      }

      setAlreadyLoaded(true);
    };

    main();
  }, [currentOrganisation, numberOfOpportunities]);

  const scrollHandler = (e) => {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      if (!opportunities) return;
      addNumberOfOpportunities();
    }
  };

  const changeWindow = (filter) => {
    history.push("/dashboard/opportunity/list/" + filter);
  };

  const addNumberOfOpportunities = () => {
    if (isFinsh) return;
    let parameters = {};
    if (filter == "all") {
      setText("toutes");
    }
    if (filter === "todo") {
      parameters = { state: "TODO" };
      setText("a faire");
    }
    if (filter === "waiting") {
      parameters = { state: "WAITING" };
      setText("en attente");
    }
    if (filter === "loose") {
      parameters = { state: "LOOSE" };
      setText("en perdues");
    }
    if (filter === "win") {
      parameters = { state: "WIN" };
      setText("en gagnées");
    }
    axios
      .post(config.server_ip + "/api/opportunity/getwithfilter", {
        token: localStorage.getItem("token"),
        organisationId: currentOrganisation,
        filter: parameters,
        startId: numberOfOpportunities,
        endId: numberOfOpportunities + 5,
      })
      .then((response) => {
        addNumberOfPropertiesWithList([
          ...opportunities,
          ...response.data.opportunitys,
        ]);
        setOpportunities([...opportunities, ...response.data.opportunitys]);
        setIsFinsh(response.data.itsFinish);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: "bottom-right",
          theme: "dark",
        });
      });
  };

  const addNumberOfPropertiesWithList = (list) => {
    if (!list) return;
    setNumberOfOpportunities(numberOfOpportunities + 5);
    if (numberOfOpportunities > list.length)
      setNumberOfOpportunities(opportunities.length);
  };
  return (
    <div className="prospect_background_component">
      <Navbar
        currentOrganisation={organisation.filter(
          (o) => o["_id"] === currentOrganisation
        )}
        selected="Home"
        allOrganisation={organisation}
        setOrga={(organisationId) => {
          setAlreadyLoaded(false);
          setOpportunities(null);
          setCurrentOrganisation(organisationId);
        }}
      />
      <div className="main">
        <div className="main_header">
          <Clock />
        </div>
        {alreadyLoaded && opportunities && (
          <div className="myMain opportunityList border-gradient border-fullscreen">
            <div className="left_header">
              <div className="left_title border-gradient border-xl">
                <p className="left_text">Opportunité {text}</p>
                <p className="left_number">{opportunities.length}</p>
              </div>
            </div>
            <div
              onScroll={(e) => {
                scrollHandler(e);
              }}
              className="myTable allFile"
            >
              {opportunities
                .slice(0, numberOfOpportunities)
                .map((opportunity) => (
                  <AllFiche key={opportunity._id} opportunity={opportunity} />
                ))}
            </div>
          </div>
        )}
        {!alreadyLoaded ||
          (!opportunities && (
            <div className="loadingScreen">
              <img src={loader} alt="logo" />
            </div>
          ))}
      </div>
      <ToastContainer autoClose={8000} />
    </div>
  );
};

export default OpportunityList;
