import React, { useState, useEffect } from "react";
import "./Clock.css";

import Profil from "../../../assets/images/icon/profil.png";

const Clock = (props) => {
  const [hour, setHour] = useState(getCurrentTime());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setHour(getCurrentTime());
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);

  function getCurrentTime() {
    const date = new Date();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  return (
    <div className="clock">
      <h3 className="ClockTitle">{hour}</h3>
      {props?.unactive == undefined && (
        <div className="clock_footer">
          <div className="notification_clock">
            <p>0</p>
          </div>
          {/* <div className="ticketCreate border-gradient border-s">
            <p>Créer Ticket</p>
          </div> */}
          <div className="profil border-gradient border-fullscreen">
            <img src={Profil} alt="profil" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Clock;
